import React, { FunctionComponent } from 'react'
import Image from 'next/image'
import {
  IMarketingModal,
  ICallToAction,
  IHubspotForm,
} from '../../@types/generated/contentful'
import RichText from '../richText'
import CallToAction from '../callToAction'
import HubspotForm from '../form/hubspotForm'

type Props = {
  content: IMarketingModal
  opened: boolean
  onOpen: Function
}

const MarketingModal: FunctionComponent<Props> = ({
  content,
  onOpen,
  opened = false,
}) => {
  const { image, title, body, cta } = content.fields

  if (!opened) return <div />

  return (
    <div
      className="fixed inset-0 z-100 flex items-center justify-center bg-modal transition-opacity duration-300 ease-in-out"
      onClick={() => onOpen(false)}
    >
      <div
        className="relative	grid max-h-full md:max-h-modal w-11/12 md:w-1/2 max-w-3xl grid-cols-1 lg:grid-cols-2 overflow-y-auto bg-white rounded-md"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="absolute top-2 right-2 z-10">
          <Image
            src="/icons/close.svg"
            className="m-0 cursor-pointer"
            alt=""
            width={24}
            height={24}
            objectFit="contain"
            onClick={() => onOpen(false)}
          />
        </div>
        <div className="hidden lg:block relative h-64 md:h-full">
          {image ? (
            <Image
              src={`https:${image.fields.file.url}`}
              alt={image.fields.description}
              objectFit="cover"
              objectPosition="center"
              layout="fill"
            />
          ) : (
            ''
          )}
        </div>
        <div className="flex flex-col items-center px-4 md:px-8 py-8">
          <div className="text-center text-3xl font-bold">{title}</div>
          <RichText content={body} />
          {cta.sys.contentType.sys.id === 'hubspotForm' ? (
            <HubspotForm content={cta as IHubspotForm} customized={true} />
          ) : (
            <CallToAction
              content={(cta as ICallToAction).fields}
              callback={() => {}}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default MarketingModal
