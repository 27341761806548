import React, { FunctionComponent, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { LINE_COLORS } from '../../constants/trending'
import { TrendingLegend } from './trendingLegend'

const Chart = dynamic(() => import('react-apexcharts'), { ssr: false })

interface ITrendingGraph {
  /**
   * Data
   */
  reports: any[]
  /**
   * List of time frames
   */
  timeFrames: string[]
}

export const TrendingGraph: FunctionComponent<ITrendingGraph> = ({
  reports,
  timeFrames,
}) => {
  const [trendingReports, setTrendingReports] = useState<any[]>([])
  const [selectedNames, selectNames] = useState<string[]>([])
  const [disabledNames, setDisabledNames] = useState<string[]>([])

  useEffect(() => {
    let names: string[] = []
    let disabledNames: string[] = []
    reports.forEach((x) => {
      const validValues = Object.keys(x).filter(
        (key) => key !== 'property' && x[key] !== 0
      )
      if (validValues.length > 0) names.push(x.property.code)
      else disabledNames.push(x.property.code)
    })
    selectNames(names)
    setDisabledNames(disabledNames)
  }, [reports])

  useEffect(() => {
    let i = -1
    const series: any[] = reports
      .map((x) => {
        i += 1
        const isSelected = selectedNames.includes(x.property.code)

        const data = Object.keys(x)
          .filter((key) => key !== 'property')
          .map((key) => (x[key] === 0 ? null : x[key]))
        return {
          code: x.property.code,
          name: x.property.name,
          data: isSelected ? data : [],
          color: LINE_COLORS[i],
        }
      })
      .filter((x) => x)
    setTrendingReports(series)
  }, [reports, selectedNames])

  const options = {
    chart: {
      id: 'data-graph',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: [
      function ({ ...props }) {
        const { seriesIndex } = props
        const item = trendingReports[seriesIndex]
        return item ? item.color : LINE_COLORS[seriesIndex]
      },
    ],
    grid: {
      show: false,
    },
    labels: timeFrames,
    markers: {
      size: 10,
      strokeColors: '#ffffff',
      strokeWidth: 2,
      hover: {
        size: 12,
      },
    },
    stroke: {
      width: 4,
    },
    legend: {
      show: false,
    },
    xaxis: {
      axisBorder: {
        show: true,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          cssClass: 'text-black font-bold text-lg',
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      min: 0,
      forceNiceScale: true,
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          cssClass: 'text-black text-lg',
        },
      },
    },
    tooltip: {
      shared: false,
      intersect: true,
      custom: function ({ ...props }) {
        const { series, seriesIndex, dataPointIndex } = props

        const item = trendingReports[seriesIndex]
        const logoUrl = `https://${process.env.NEXT_PUBLIC_CDN_URL}/property-images/${item.code}.png`

        let offset = 0
        if (
          dataPointIndex > 0 &&
          series[seriesIndex][dataPointIndex] !== null &&
          series[seriesIndex][dataPointIndex - 1] !== null
        ) {
          offset =
            series[seriesIndex][dataPointIndex] -
            series[seriesIndex][dataPointIndex - 1]
        }

        const tooltip = `
          <div class="bg-white rounded-md shadow-md p-5 flex flex-col relative">
            <span class="font-semibold">${timeFrames[dataPointIndex]}</span>
            <div class="flex items-center">
              <img src=${logoUrl} alt=${item.name} class="w-8 object-contain" />
              <div class="flex flex-col ml-2">
                <span>${item.name}</span>
                <div class="flex items-center font-semibold leading-none">
                  <span>${series[seriesIndex][dataPointIndex]}</span>
                  <span class='${offset !== 0 ? 'ml-1' : ''} ${
          offset > 0 ? 'text-green-600' : offset < 0 ? 'text-red-600' : ''
        }'>${offset !== 0 ? (offset > 0 ? '+' : '') + offset : ''}</span>
                </div>
              </div>
            </div>
          </div>
        `
        return tooltip
      },
    },
  }

  const updateReports = (name: string) => {
    if (selectedNames.includes(name))
      selectNames(selectedNames.filter((x) => x !== name))
    else selectNames((prev) => [...prev, name])
  }

  return (
    <div className="flex flex-col">
      <Chart
        options={options}
        series={trendingReports}
        type="line"
        height={400}
      />
      <div className="flex flex-col flex-wrap items-center justify-center gap-1 md:flex-row">
        {reports.map((report, index) => (
          <TrendingLegend
            key={index}
            propertyCode={report.property.code}
            propertyName={report.property.name}
            selected={selectedNames.includes(report.property.code)}
            legendIndex={index}
            callback={() => updateReports(report.property.code)}
            disabled={disabledNames.includes(report.property.code)}
          />
        ))}
      </div>
    </div>
  )
}
