import React, { FunctionComponent, useEffect, useState } from 'react';
import { ISolutionFocus, ISolutionSection } from '../../../@types/generated/contentful';
import TitleBrick from '../../titleBrick';
import SolutionFocus from '../solutionFocus';

type Props = {
    content: ISolutionSection,
};

const SolutionSection: FunctionComponent<Props> = ({ content }) => {
	const [activeFocus, setActiveFocus] = useState<ISolutionFocus>(content.fields.focuses[0]);

	const { titleBrick, focuses } = content.fields;
	
	useEffect(() => {
		if (focuses && focuses.length > 0) setActiveFocus(focuses[0]);
	}, [focuses]);

    return (
        <div className="flex flex-col">
			<TitleBrick content={titleBrick} />
			<div style={{backgroundColor: titleBrick.fields.background || "white"}}>
				<div className="container mx-auto w-full md:w-fit flex items-center -mt-6 p-6 md:px-8 md:pt-0 md:pb-8">
					{focuses?.map((focus) => (
						<div
							key={focus.sys.id}
							className={`body-small w-1/2 md:w-52 h-20 text-center px-4 py-2 rounded ${activeFocus?.fields.title === focus.fields.title ? 'text-white bg-sp-primary shadow-xl' : 'text-black bg-white'} flex justify-center items-center cursor-pointer`}
							onClick={() => setActiveFocus(focus)}
						>
							{focus.fields.title}
						</div>
					))}
				</div>
			</div>
			<SolutionFocus content={activeFocus} />
        </div>
    );
};

export default SolutionSection;
