import React, { FunctionComponent, useState } from 'react';
import { IFaqSection } from '../../@types/generated/contentful';
import FaqTile from '../faqTile';

type Props = {
    content: IFaqSection,
};

const FaqSection: FunctionComponent<Props> = ({ content }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const { title, questions } = content.fields;

    return (
        <div className="container mx-auto flex flex-col items-left md:items-center px-6 py-12 md:px-8 md:py-20">
            <h2>{title}</h2>
            <div className="flex flex-col w-full md:w-8/12 mx-auto mt-2 md:mt-6">
                {questions?.map((question, index) => (
                    <FaqTile
                        key={question.sys.id}
                        content={question}
                        opened={index === activeIndex}
                        onOpen={(open: boolean) => setActiveIndex(open ? index : -1)}
                    />
                ))}
            </div>
        </div>
    );
};

export default FaqSection;
