import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  isProperty,
  isConsumerSegment,
  isDemographic,
  isBrand,
  isDigitalBehavior,
} from './propertyAxis'
import { IProperty } from '../../@types/common'
import { IConsumerSegment } from '../../@types/dataTable'

interface IReportLabel {
  /**
   * Property report
   */
  report: {
    consumer_segment?: IConsumerSegment
    property?: IProperty
  }
}

export const ReportLabel: FunctionComponent<IReportLabel> = ({ report }) => {
  const [label, setLabel] = useState('')

  const getLabel = (report: any) => {
    if (report.property && isProperty(report.property)) {
      const property: IProperty = report.property
      return property.name
    }

    if (report.consumer_segment && isConsumerSegment(report.consumer_segment)) {
      const consumerSegment: IConsumerSegment = report.consumer_segment
      const prefix = isDemographic(consumerSegment)
        ? `${consumerSegment.category?.name}: `
        : ''
      const postfix =
        isBrand(consumerSegment) || isDigitalBehavior(consumerSegment)
          ? ' users'
          : ''

      return `${prefix}${consumerSegment.name}${postfix}`
    }
    return ''
  }

  useEffect(() => {
    if (!report) return
    setLabel(getLabel(report))
  }, [report])

  return (
    <span className="mt-1 text-center text-sm font-normal text-sp-purple">
      {label}
    </span>
  )
}
