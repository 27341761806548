import { IField } from "../@types/hubspot";
import { INVALID_EMAIL, REQUIRED_FIELD } from '../services/constants'

function validEmail(email: string) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

export function validateForm(fields: IField[]) {
    fields.map((field) => {
        return validateField(field)
    })
}

export function validateField(field: IField) {
    let error = ''
    
    if (field.required) error = requiredField(field)
    if ((field.fieldType === 'email' && field.required) || (field.fieldType === 'email' && field.value !=='' && !field.required)) error = validateEmail(field)
    return error
}

function validateEmail(field: IField): string {
    if (!validEmail(field.value as string))
        return INVALID_EMAIL
    return ''
}

function requiredField(field: IField): string {
    if (!field.value) 
        return `${field.label} ${REQUIRED_FIELD}`;
    return ''
}