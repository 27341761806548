import React, { FunctionComponent } from 'react';
import { ISocialLink } from '../../@types/generated/contentful';
import SocialLink from '../socialLink'

type Props = {
    links: ISocialLink[] | undefined
}

const SocialLinks: FunctionComponent<Props> = ({links}) => {
    const linkList = (links) ? links.map((link) => {
        return <SocialLink key={link.sys.id} url={link.fields.url} icon={link.fields.icon.fields.file.url} alt={link.fields.title} />
    }) : <></>
    return (
        <div className="mx-auto mt-4">
            <ul className="flex justify-center md:justify-start">
                { linkList }
            </ul>
        </div>
    );
};

export default SocialLinks;
