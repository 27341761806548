import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { IHubspotForm } from '../../@types/generated/contentful'
import { IForm, IFieldGroup, IField, IFormState } from '../../@types/hubspot'
import { validateField } from '../../helpers/formValidation'
import Disclaimer from './disclaimer'
import FieldGroup from './fieldGroup'
import FormError from './formError'
import FormSubmit from './formSubmit'
import RichText from '../richText'
import { UTM } from '../../services/utm'
import { clearQuery, sendEvent } from '../../helpers/analytics'

type Props = {
  content: IHubspotForm
  customized: boolean
}

const HubspotForm: FunctionComponent<Props> = ({
  content,
  customized = false,
}) => {
  const formRef = useRef<HTMLDivElement>(null)
  const [isLoading, setLoading] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [isComplete, setCompleted] = useState(false)
  const [hsForm, setForm] = useState<IForm | null>(null)
  const [fields, setFields] = useState<IFormState>({})
  const [error, setError] = useState('')

  useEffect(() => {
    setLoading(true)
    fetch(`/api/form/${content.fields.formId}`)
      .then((res) => res.json())
      .then((data) => {
        let fieldGroups = {}
        setFields(
          data.fieldGroups.reduce((object: any, item: IFieldGroup) => {
            let fields = {}
            const field = item.fields.reduce((object, item: IField) => {
              return {
                ...object,
                [item.name]: {
                  ...item,
                  value: item.defaultValue ? item.defaultValue : '',
                  error: '',
                },
              }
            }, fields)
            return {
              ...object,
              ...field,
            }
          }, fieldGroups)
        )
        setForm(data as IForm)
        setLoading(false)
      })
  }, [content.fields.formId])

  const handleFieldUpdate = (field: IField) => {
    let error = validateField(field)

    setFields({
      ...fields,
      [field.name]: {
        ...field,
        error: error,
      },
    })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError('')
    let hasError = false
    let formValidation = {}
    let submission = {
      fields: {},
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    }

    for (let field in fields as IFormState) {
      let error = validateField(fields[field])
      hasError = error != '' ? true : hasError
      formValidation = {
        ...formValidation,
        [field]: {
          ...fields[field],
          error: error,
        },
      }
      submission.fields = {
        ...submission.fields,
        [field]: fields[field].value,
      }
    }

    clearQuery()
    sendEvent('Form Submission', {
      pageUri: window.location.href,
      page: document.title,
      'form-id': content.fields.formId,
      'form-title': content.fields.title,
    })

    // add utm parameters, if any
    const utm = new UTM()
    submission.fields = {
      ...submission.fields,
      ...utm.getUtm(),
    }

    if (hasError) {
      setFields(formValidation)
      formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      setSubmitting(true)
      fetch(`/api/form/${content.fields.formId}`, {
        method: 'post',
        headers: new Headers({ 'content-type': 'application/json' }),
        body: JSON.stringify(submission),
      })
        .then((res) => {
          if (!res.ok) throw res
          return res.json()
        })
        .then(() => {
          setSubmitting(false)
          setCompleted(true)
          utm.clearUtm()
        })
        .catch((error) => {
          setSubmitting(false)
          error.json().then((body: any) => {
            setError(body.error)
            formRef.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          })
        })
    }
  }

  const formComponent = () => {
    if (isLoading) return <></>
    else if (!isLoading && !hsForm)
      return <p>Something went wrong, please reload</p>
    else if (hsForm) {
      return (
        <form
          className={`${isComplete ? 'opacity-0' : 'opacity-1'} ${
            customized ? 'mx-auto md:max-w-md' : ''
          } transition-opacity duration-100 ease-in-out`}
          onSubmit={handleSubmit}
          noValidate
        >
          <FormError message={error} />
          <div className="extra-small-label pb-4">
            All fields are mandatory unless otherwise noted.
          </div>
          <fieldset disabled={isSubmitting}>
            {hsForm.fieldGroups.map((group, index) => {
              return (
                <FieldGroup
                  key={`group-${index}`}
                  fieldGroup={group}
                  fields={fields}
                  callback={handleFieldUpdate}
                />
              )
            })}
          </fieldset>
          <FormSubmit isSubmitting={isSubmitting} isCustom={customized} />
          <Disclaimer />
        </form>
      )
    }
  }

  if (customized) {
    return (
      <>
        <div
          className={`${
            isComplete ? 'h-fit p-4 md:p-8' : 'h-0 overflow-hidden'
          }`}
          style={{ backgroundColor: content.fields.background }}
        >
          <div className="w-full text-center text-3xl font-bold">
            {content.fields.successTitle}
          </div>
          {content.fields.successBody ? (
            <p className="py-2 text-center">{content.fields.successBody}</p>
          ) : content.fields.richSuccessBody ? (
            <div className="text-center">
              <RichText content={content.fields.richSuccessBody} />
            </div>
          ) : (
            ''
          )}
        </div>
        <div
          className={`${isComplete ? 'hidden' : 'h-fit p-2'}`}
          style={{ backgroundColor: content.fields.background }}
          ref={formRef}
        >
          {formComponent()}
        </div>
      </>
    )
  }

  return (
    <div
      className="container my-6 mx-auto grid scroll-mt-24 grid-cols-1 lg:my-28 lg:grid-cols-12"
      ref={formRef}
    >
      <div
        className={`mx-4 lg:m-0 ${
          content.fields.richDescription ? 'lg:col-span-6' : 'lg:col-span-4'
        } text-center text-sp-purple lg:px-10 lg:text-left`}
      >
        <h2 className="mb-5">{content.fields.title}</h2>
        {content.fields.description ? (
          <span className="body-medium mb-5">{content.fields.description}</span>
        ) : content.fields.richDescription ? (
          <RichText content={content.fields.richDescription} />
        ) : (
          ''
        )}
      </div>
      <div
        className={`mx-4 mt-4 lg:m-0 ${
          content.fields.richDescription
            ? 'lg:col-span-5 lg:col-start-8'
            : 'lg:col-span-6 lg:col-start-7'
        } max-h-[1000px] py-4 px-4 transition-max-height duration-500 lg:py-10 lg:px-20 ${
          isComplete ? 'max-h-44' : ''
        } h-fit ease-in-out`}
        style={{ backgroundColor: content.fields.background }}
      >
        <div className={`${isComplete ? 'h-fit' : 'h-0 overflow-hidden'}`}>
          <h3 className="mb-5">{content.fields.successTitle}</h3>
          {content.fields.successBody ? (
            <p className="body-medium">{content.fields.successBody}</p>
          ) : content.fields.richSuccessBody ? (
            <RichText content={content.fields.richSuccessBody} />
          ) : (
            ''
          )}
        </div>
        <div className={`my-auto`}>{formComponent()}</div>
      </div>
    </div>
  )
}

export default HubspotForm
