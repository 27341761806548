import React, { FunctionComponent } from 'react'
import { ReportImage } from './reportImage'
import { ReportLabel } from './reportLabel'
import { IProperty } from '../../@types/common'
import { IConsumerSegment } from '../../@types/dataTable'

interface Props {
  data: any[]
}

export const isProperty = (report: any): report is IProperty => report.name
export const isConsumerSegment = (report: any): report is IConsumerSegment =>
  report.name
export const isBrand = (report: any): report is IConsumerSegment =>
  report.type === 'brand'
export const isDemographic = (report: any): report is IConsumerSegment =>
  report.type === 'demographic'
export const isPurchaseBehavior = (report: any): report is IConsumerSegment =>
  report.type === 'purchase_behavior'
export const isDigitalBehavior = (report: any): report is IConsumerSegment =>
  report.type === 'digital_behavior'

export const PropertyAxis: FunctionComponent<Props> = ({ data }) => {
  return (
    <div className="col-start-2 row-start-2 grid grid-cols-3 md:grid-cols-9">
      {data.map((report: any, index) => (
        <div
          key={index}
          className={`flex flex-col items-center p-1 ${
            index >= 3 ? 'hidden md:flex' : ''
          }`}
        >
          <ReportImage report={report} />
          <ReportLabel report={report} />
        </div>
      ))}
    </div>
  )
}
