import React, { FunctionComponent, useState } from 'react';
import { IField } from '../../../@types/hubspot';
import FormFieldError from '../fieldError'

type Props = {
    field: IField,
    callback: Function
}

const FormCheckbox: FunctionComponent<Props> = ({ field, callback }) => {
    const [checked,setChecked] = useState((field.defaultValue === 'true')? true: false)
    
    const validateField = () => {
        setChecked(!checked);
        callback({
            ...field,
            value: !checked
        });
    }

    return (
        <div className='relative z-0 h-8 mb-6 group'>
            <label 
                htmlFor={field.name}
                className='flex items-center'
            >
                <input 
                    type='checkbox'
                    id={field.name}
                    name={field.name}
                    className={`form-check-input border-sp-mid-grey text-sp-primary w-8 h-8 rounded ${(field.error)? 'bg-sp-error-red border border-red-500':'bg-white'}`}
                    required={field.required}
                    defaultChecked={checked}
                    aria-required={field.required}
                    aria-invalid={(field.error) ? true : false}
                    aria-errormessage={`error-${field.name}`}
                    onChange={validateField}
                />
                <span className='body-medium ml-2'>
                    {field.label}
                </span>
            </label>
            <FormFieldError field={field} />
        </div>
    );
};

export default FormCheckbox;