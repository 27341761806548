import React, { FunctionComponent } from 'react';
import { isMobile } from 'react-device-detect';
import Image from 'next/image';
import ReactMarkdown from 'react-markdown';
import { ISponsorship } from '../../../@types/generated/contentful';

type Props = {
	content: ISponsorship
};

const Sponsorship: FunctionComponent<Props> = ({ content }) => {
    const { title, body, image, featuredProperties } = content.fields;

    const imgWidth = image ? image.fields.file.details.image?.width || 0 : 0;
    const imgHeight = image ? image.fields.file.details.image?.height || 0 : 0;

    return (
		<div className="h-full bg-white rounded">
			<div className="flex flex-col h-full">
                <div className="flex flex-row md:flex-col items-center md:items-start md:justify-end h-[8rem] md:h-[16rem] relative p-4">
                    <h3 className="w-9/12 mr-auto md:mr-0">{title}</h3>
                    {image ? (
                        <div className="md:absolute md:top-4 md:right-8">
                            <Image
                                src={`https:${image.fields.file.url}`}
                                alt={image.fields.description}
                                width={isMobile ? imgWidth / 2 : imgWidth}
                                height={isMobile ? imgHeight / 2 : imgHeight}
                                objectFit="contain"
                            />
                        </div>
                    ) : ""}
                </div>
                <ReactMarkdown className="medium-markdown flex-1 px-4 py-6 border-t border-sp-primary">{body || ""}</ReactMarkdown>
                <div className="body-small px-4 py-2">FEATURED PROPERTIES</div>
                <div className="flex items-center p-4">
                    {featuredProperties?.map(featuredProperty => (
                        <div key={featuredProperty.sys.id} className="flex-1 mx-2 h-10 relative">
                            <Image
                                src={`https:${featuredProperty.fields.file.url}`}
                                alt={featuredProperty.fields.description}
                                objectFit="contain"
                                layout="fill"
                            />
                        </div>
                    ))}
                </div>
			</div>
		</div>
    );
};

export default Sponsorship;
