import React, { FunctionComponent } from 'react';
import Image from 'next/image'
import Link from 'next/link'

import { IFooterFields } from '../../@types/generated/contentful';

import SocialLinks from '../socialLinks';
import FooterNavigation from '../footerNavigation';
import NewsletterForm from './newsLetterForm';

type Props = {
    content: IFooterFields | undefined
}

const Footer: FunctionComponent<Props> = ({content}) => {
    const navigation = (content?.navigation) ? content.navigation.map((nav) => {
        return <FooterNavigation key={nav.sys.id} content={nav} />
    }) : <></>

    return (
        <footer className='flex w-full md:h-60 md:px-4 bg-sp-purple z-20'>
            <div className='container grid-rows-2 mx-auto text-white relative'>
                <div className='grid grid-cols-12 mt-8 mb-8'>
                    <div className='col-span-12 md:col-span-3 grid-rows-2 mx-4 md:mx-0'>
                        <div className='hidden md:block mb-2'>
                            <Link href='/'>
                                <a>
                                    <Image
                                        src='/sponsorpulse-logo-white.png'
                                        alt='SponsorPulse'
                                        width={164}
                                        height={31}
                                        unoptimized={true}
                                    />
                                </a>
                            </Link>
                        </div>
                        <div className='flex flex-row justify-center md:justify-start'>
                            <div className='md:hidden mb-2'>
                                <Link href='/'>
                                    <a>
                                        <Image
                                            src='/sponsorpulse-badge-white.png'
                                            alt='SponsorPulse'
                                            width={31}
                                            height={31}
                                            unoptimized={true}
                                        />
                                    </a>
                                </Link>
                            </div>
                            <div className='inline-block align-middle font-light text-base md:text-sm ml-4 md:ml-0'>
                                Better sponsorship decisions, <span className='md:block font-bold'>faster.</span>
                            </div>
                        </div>
                    </div>
                    { navigation }
                    <div className='col-span-12 md:col-span-5 md:col-start-8 text-sm text-center md:text-left mb-4 md:mb-0 items-center justify-center md:justify-items-start md:justify-left'>
                        <div className='font-extralight text-xs uppercase my-2'>subscribe to our newsletter</div>
                        <NewsletterForm/>  
                        <SocialLinks links={content?.socialLinks} />
                    </div>
                </div>
                <div className='mx-4 md:mx-0 absolute bottom-0 left-0 right-0'>
                    <div className="m-auto font-light text-xs text-center md:text-left mb-6">
                        {new Date().getFullYear()} All rights reserved.
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;