import React, { FunctionComponent, useState } from 'react'
import { isOpportunityScore } from '../../helpers/utils'

interface Props {
  data: any[]
  sortBy: string
  callback: Function
}

export const GraphBar: FunctionComponent<Props> = ({
  data,
  sortBy,
  callback,
}) => {
  const [selectedGraph, setSelectedGraph] = useState<number>(-1)

  const handleSelect = (index: number) => {
    const newIndex = index === selectedGraph ? -1 : index
    setSelectedGraph(newIndex)
    callback(newIndex)
  }

  const renderBarColor = (index: number) => {
    let color = ''

    if (index === selectedGraph) {
      color = isOpportunityScore(sortBy)
        ? 'bg-sp-primary-dark'
        : 'bg-sp-green-dark'
    } else {
      color = isOpportunityScore(sortBy) ? 'bg-sp-primary' : 'bg-sp-green'
    }
    color += isOpportunityScore(sortBy)
      ? ' hover:bg-sp-primary-dark'
      : ' hover:bg-sp-green-dark'

    return color
  }

  const suffix = !isOpportunityScore(sortBy) ? '%' : ''
  return (
    <div className="relative grid grid-cols-3 flex-wrap overflow-hidden border-l border-b border-gray-400 md:grid-cols-9">
      {data.map((report: any, index) => (
        <div
          key={index}
          className={`z-20 flex flex-col items-center justify-end ${
            index >= 3 ? 'hidden md:flex' : ''
          }`}
        >
          <span className="text-sm font-medium text-sp-purple">
            {report[sortBy]} {suffix}
          </span>
          <div
            className={`animate-bar mx-auto w-12 cursor-pointer rounded-t-md lg:w-16 ${renderBarColor(
              index
            )}`}
            style={{ height: `${(report[sortBy] / 10) * 40 - 1}px` }}
            onClick={() => handleSelect(index)}
          />
        </div>
      ))}
    </div>
  )
}
