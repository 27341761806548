import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import { ITestimonialTile } from '../../@types/generated/contentful';
import ProfilePic from '../profilePic';

type Props = {
    content: ITestimonialTile,
    index: number,
    className: string,
};

const TestimonialTile: FunctionComponent<Props> = ({ content, index, className }) => {
    const { text, image, name, organization } = content.fields;

    return (
        <div className={`w-full md:w-80 h-auto md:h-full bg-white flex flex-col shadow-lg rounded ${className}`}>
            <div className="p-6 flex-1">
                <Image
                    src={`/icons/${index % 2 === 0 ? "quotes_purple" : "quotes_green"}.svg`}
                    alt="Sponsorpulse"
                    width={24}
                    height={17}
                    objectFit="contain"
                />
                <div className="font-light leading-tight text-sp-dark-grey text-base mt-3">{text}</div>
            </div>
            <div className="relative -mb-2">
                <Image
                    src={`/images/${index % 2 === 0 ? "testimonials_purple" : "testimonials_green"}.svg`}
                    className="rounded-b"
                    alt="Sponsorpulse"
                    width={628}
                    height={index % 2 === 0 ? 302 : 345}
                    objectFit="contain"
                />
                <div className="absolute inset-0 flex justify-center">
                    <ProfilePic
                        image={image?.fields.file.url}
                        name={image?.fields.title}
                        className={`${index % 2 === 0 ? "-mt-2" : "mt-2"} ml-4`}
                    />
                </div>
                <div className={`absolute inset-x-0 ${index % 2 === 0 ? "top-8" : "top-10"} bottom-0 px-4 py-8 md:py-6 flex flex-col items-center justify-center`}>
                    <div className={`body-small ${index % 2 === 0 ? "text-white" : "text-black"} text-center`}>{name}</div>
                    <div className={`body-extra-small ${index % 2 === 0 ? "text-white" : "text-black"} text-center`}>{organization}</div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialTile;