import { useEffect, useState } from 'react'
import App, { AppContext, AppProps } from 'next/app'
import Script from 'next/script'
import { UTM } from '../services/utm'
import { ContentfulService } from '../services/contentful'
import MarketingModal from '../components/marketingModal'
import { IMarketingModal } from '../@types/generated/contentful'
import dynamic from 'next/dynamic'
import '../styles/globals.css'
import '../styles/tooltips.css'

type Props = Pick<AppProps, 'Component' | 'pageProps'> & {
  modals: IMarketingModal[] | []
}

const CookieNotice = dynamic(() => import('../components/cookieNotice'), {
  ssr: false,
})

function SponsorPulseMarketing({ Component, pageProps, modals }: Props) {
  const [opened, setOpened] = useState(false)
  const [modal, setModal] = useState<IMarketingModal | null>(null)

  useEffect(() => {
    const utm = new UTM()
    if (modals.length <= 0) return

    const utm_campaign = utm.getUtm().utm_campaign

    const modalIndex = modals.findIndex(
      (modal: IMarketingModal) => modal.fields.campaign === utm_campaign
    )

    let modal: IMarketingModal
    if (modalIndex >= 0) {
      modal = modals[modalIndex]
    } else {
      const uc_modals = modals.filter((modal: IMarketingModal) => !modal.fields.campaign || modal.fields.campaign.length <= 0)
      if (uc_modals.length <= 0) return;
      modal = uc_modals[Math.floor(Math.random() * uc_modals.length)]
    }
    setModal(modal)
    setTimeout(() => {
      setOpened(true)
    }, modal.fields.triggerAfter * 1000)
  }, [])

  return (
    <>
      <Script id="gtm-tag" strategy="afterInteractive">
        {`
					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','GTM-N9MS3N5');
				`}
      </Script>
      <Component {...pageProps} />
      {modal ? (
        <MarketingModal
          content={modal}
          opened={opened}
          onOpen={(opened: boolean) => setOpened(opened)}
        />
      ) : (
        ''
      )}
      <CookieNotice/>
    </>
  )
}

SponsorPulseMarketing.getInitialProps = async (context: AppContext) => {
  const ctx = await App.getInitialProps(context)

  const contentfulService = new ContentfulService()
  const modals = await contentfulService.getMarketingModals()
  return { ctx, modals }
}

export default SponsorPulseMarketing
