import React, { FunctionComponent } from 'react';
import FormFieldError from '../fieldError';
import { IField } from '../../../@types/hubspot';

type Props = {
    field: IField,
    callback: Function

}
const FormSelect: FunctionComponent<Props> = ({ field, callback }) => {
    const options = field.options?.map((option) => {
        return <option key={`${option.label}-${option.value}`} value={option.value}>{option.label}</option>
    })

    const handleChange = (value: string) => {
        callback({
            ...field,
            value: value
        })
    }

    return (
        <div className='relative z-0 w-full mb-6 group'>
            <select id={field.name} name={field.name} className={`form-select h-10 px-2 px-3 ${(field.error)? 'bg-sp-error-red border border-red-500':'border border-sp-mid-grey bg-white'} ${(field.value) ? 'text-sp-purple':'text-sp-mid-grey'} text-base rounded block w-full`} onChange={(event) => handleChange(event.target.value)}>
                <option value=''>{`${field.label}${(!field.required) ? ' (optional)':''}`}</option>
                { options }
            </select>
            <FormFieldError field={field} />
        </div>

    );
};

export default FormSelect;