import React, { FunctionComponent } from 'react';
import Image from 'next/image'

type Props = {
    url: string,
    icon: string,
    alt: string
}

const SocialLink: FunctionComponent<Props> = ({url, icon, alt}) => {
    return (
        <li className="mr-3">
            <a href={url} rel='noopener noreferrer' target='_blank'>
                <Image 
                    src={icon}
                    alt={alt}
                    width={32}
                    height={32}
                    unoptimized={true}
                />
            </a>
        </li>
    );
};

export default SocialLink;