import React, { FunctionComponent, useState, useEffect } from 'react';
import { ISolutionFocus, ISolutionTab } from '../../../@types/generated/contentful';
import SolutionTab from '../solutionTab';

type Props = {
	content: ISolutionFocus
};

const SolutionFocus: FunctionComponent<Props> = ({ content }) => {
	const [activeTab, setActiveTab] = useState<ISolutionTab>(content.fields.tabs[0]);
	
	const { tabs } = content.fields;

	useEffect(() => {
		if (tabs && tabs.length > 0) setActiveTab(tabs[0]);
	}, [tabs]);

    return (
		<div className="container mx-auto p-6 md:px-8 lg:px-10 md:py-12 lg:py-16 flex flex-col">
			<div className="flex flex-row overflow-scroll no-scrollbar border-b-2 md:border-b-0">
				{tabs.map((tab, index) => (
					<div
						key={tab.sys.id}
						className={`w-30 md:w-full flex-none md:flex-1 flex flex-col px-0 py-1 md:px-8 md:py-2 ${index === 0 ? 'm-0' : 'ml-4 md:ml-0' } ${activeTab?.fields.label === tab.fields.label ? 'bg-white md:bg-sp-light-purple border-b-4 border-t-0 md:border-b-0 md:border-t-4 border-sp-primary' : 'bg-white'} cursor-pointer`}
						onClick={() => setActiveTab(tab)}
					>
						<div className="seo-copy text-sp-dark-grey">{tab.fields.label.toUpperCase()}</div>
						<div className="sub-heading-medium font-medium">{tab.fields.name}</div>
					</div>
				))}
			</div>
			<SolutionTab content={activeTab} />
		</div>
    );
};

export default SolutionFocus;
