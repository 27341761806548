import React, { FunctionComponent } from 'react';
import { IHomeSolutions } from '../../../@types/generated/contentful';
import SolutionTile from '../../solutionTile';

type Props = {
    content: IHomeSolutions,
};

const HomeSolutions: FunctionComponent<Props> = ({ content }) => {
    const { seoCopy, title, body, tiles } = content.fields;
    const largeColumns = (tiles) ? 12 / tiles.length : 0;

    let style = ""
    switch (largeColumns) {
        case 3:
            style = "md:col-span-6 lg:col-span-3";
            break;
        case 4: 
            style = "md:col-span-4 lg:col-span-4";
            break;
    }

    return (
        <div className="container mx-auto flex flex-col p-6 md:px-8 md:py-20">
            <div className="w-full xl:w-3/4">
                <div className="seo-copy">{seoCopy}</div>
                <h2 className="my-1">{title}</h2>
                <div className="body-medium mt-4">{body}</div>
            </div>
            <div className="grid grid-cols-12 mt-4 md:mt-8 lg:mt-16">
                {tiles?.map((solutionTile) => (
                    <SolutionTile
                        key={solutionTile.sys.id}
                        content={solutionTile}
                        className={`col-span-12 ${style} mx-0 my-4 lg:my-0 md:px-4`}
                    />
                ))}
            </div>
        </div>
    );
};

export default HomeSolutions;
