import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import { IStorySection } from '../../../@types/generated/contentful';
import RichText from '../../richText';

type Props = {
    content: IStorySection,
};

const StorySection: FunctionComponent<Props> = ({ content }) => {
    const { seoCopy, title, body, image, background } = content.fields;

    return (
        <div className="container mx-auto flex flex-col md:flex-row p-0 md:p-8">
            {image ? (
                <div className="relative h-72 md:h-[58rem] flex-none md:flex-1 m-4 md:m-0">
                    <div className="absolute block md:hidden inset-x-0 bottom-0 h-3/6 -m-4" style={{backgroundColor: background}} />
                    <Image
                        src={`https:${image.fields.file.url}`}
                        alt={image.fields.description}
                        objectFit="cover"
                        layout="fill"
                    />
                </div>
            ) : ""}
            <div className="flex-none md:flex-1 flex flex-col px-4 pt-4 pb-10 md:px-12 md:py-10 my-0 md:mt-12 md:mb-6" style={{backgroundColor: background}}>
                <div className="seo-copy">{seoCopy}</div>
                <h2 className="my-1 md:my-4">{title}</h2>
                {/* body-medium */}
                <RichText content={body} />
            </div>
        </div>
    );
};

export default StorySection;
