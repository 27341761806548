import React, { FunctionComponent } from 'react';
import { ISponsorshipSection } from '../../../@types/generated/contentful';
import Carousel from '../../carousel';
import Sponsorship from '../sponsorship';

type Props = {
    content: ISponsorshipSection,
};

const SponsorshipSection: FunctionComponent<Props> = ({ content }) => {
	const { sponsorships, background } = content.fields;
	
    return (
		<div style={{backgroundColor: background}}>
			<div className="container mx-auto p-6 md:px-10 md:py-16">
				<div className="block xl:hidden">
					<Carousel primary>
						{sponsorships?.map((sponsorship, index) => (
							<div
								key={sponsorship.sys.id}
								className={`w-72 flex-none ${index === 0 ? 'm-0' : 'ml-4'} drop-shadow-xl`}
							>
								<Sponsorship content={sponsorship} />
							</div>
						))}
					</Carousel>
				</div>
				<div className="hidden xl:grid grid-cols-12">
					{sponsorships?.map((sponsorship, index) => (
						<div
							key={sponsorship.sys.id}
							className={`col-span-3 ${index === 0 ? 'm-0' : 'ml-4'} drop-shadow-xl`}
						>
							<Sponsorship content={sponsorship} />
						</div>
					))}
				</div>
			</div>
		</div>
    );
};

export default SponsorshipSection;
