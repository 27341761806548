import React, { FunctionComponent } from 'react';
import FooterNavigationLink from '../footerNavigationLink';

import { INavigation } from '../../@types/generated/contentful';

type Props = {
    content: INavigation
}

const FooterNavigation: FunctionComponent<Props> = ({content}) => {
    const links = content.fields.links.map((link) => {
        return <FooterNavigationLink key={link.sys.id} content={link} />
    })
    return (
        <div className='col-span-12 md:col-span-2 text-center md:text-left mb-4'>
            <div className='font-extralight text-xs uppercase my-2'>{content.fields.title}</div>
            <ul className='font-light text-lg md:text-base'>
                { links }
            </ul>
        </div>
    );
};

export default FooterNavigation;