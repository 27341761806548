import React, { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import { IHomeApproach } from '../../../@types/generated/contentful';
import Video from '../../video';
import dynamic from 'next/dynamic';

type Props = {
    content: IHomeApproach,
};

const CallToAction = dynamic(() => import('../../callToAction'), {
    ssr: false,
  })

const HomeApproach: FunctionComponent<Props> = ({ content }) => {
    const { seoCopy, title, body, ctaButton, video, background } = content.fields;

    const btnCTA = ctaButton
        ? <CallToAction key={ctaButton.sys.id} content={ctaButton.fields} callback={() => {}}/>
        : <></>;

    return (
        <div style={{backgroundColor: background}}>
            <div className="container mx-auto flex flex-col p-6 md:p-8">
                <div className="flex flex-col w-full xl:w-6/12">
                    <div className="seo-copy">{seoCopy}</div>
                    <h2 className="my-1">{title}</h2>
                    <ReactMarkdown className="medium-markdown mt-4">{body || ""}</ReactMarkdown>
                    <div className="mt-4 md:mt-6">
                        { btnCTA }
                    </div>
                </div>
                {video ? (
                    <Video content={video} />
                ) : ""}
            </div>
        </div>
    );
};

export default HomeApproach;
