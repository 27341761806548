import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import { ISolutionTestimonial } from '../../../@types/generated/contentful';

type Props = {
    content: ISolutionTestimonial,
};

const SolutionTestimonial: FunctionComponent<Props> = ({ content }) => {
    const { quote, citation, image } = content.fields;

    return (
        <div className="bg-sp-green">
            <div className="container mx-auto flex flex-col md:flex-row items-center px-6 py-8 md:px-12 md:py-8">
                <div className="relative bg-white p-2 rounded-full w-40 h-40 md:w-52 md:h-52 order-2 md:order-1 overflow-hidden">
                    {image ? (
                        <Image
                            src={`https:${image.fields.file.url}`}
                            alt={image.fields.description}
                            objectFit="contain"
                            layout="fill"
                        />
                    ) : ""}
                </div>
                <div className="flex-none md:flex-1 flex flex-col order-1 md:order-2 ml-0 mb-4 md:ml-10 md:mb-0">
                    <div className="inline-block mb-3 md:mb-6">
                        <div className="inline-block -translate-y-2/3 mr-2">
                            <Image
                                src="/icons/quote_left.svg"
                                alt="Sponsorpulse"
                                width={20}
                                height={15}
                            />
                        </div>
                        <span className="quote indent-5">{quote}</span>
                        <div className="inline-block -translate-y-2/3 ml-2">
                            <Image
                                src="/icons/quote_right.svg"
                                alt="Sponsorpulse"
                                width={20}
                                height={15}
                            />
                        </div>
                    </div>

                    <div className="body-text ml-6 md:ml-7">{citation}</div>
                </div>
            </div>
        </div>
    );
};

export default SolutionTestimonial;
