import React, { FunctionComponent, useEffect, useState } from 'react'

import { DataGraph } from '../dataGraph'
import { GeographyMap } from '../geographyMap'
import { ProTip } from '../proTip'
import { TrendingGraph } from '../trendingGraph'
import { IReport } from '../../@types/generated/contentful'
import { TableType } from '../../@types/dataTable'
import { fetchPermalinkReport } from '../../services/api'
import { METRIC_OPTIONS } from '../../constants/app'

type Props = {
  content: IReport
}

const Report: FunctionComponent<Props> = ({ content }) => {
  const [loading, setLoading] = useState(false)
  const [report, setReport] = useState<any>(null)

  const { title, reportId } = content.fields

  useEffect(() => {
    if (!reportId) return

    const loadReports = async () => {
      setLoading(true)
      try {
        const response = await fetchPermalinkReport(reportId)
        if (response) {
          const { report_type, report_response } = response
          if (
            report_type !== 'compare' &&
            report_type !== 'analyze' &&
            report_type !== 'trending' &&
            report_type !== 'compare_geography'
          )
            throw new Error('Invalid report type')

          if (
            !report_response ||
            !report_response.reports ||
            report_response.reports.length === 0
          )
            throw new Error('No reports')

          setReport(response)
        }
      } catch (e) {}
      setLoading(false)
    }
    loadReports()
  }, [reportId])

  const renderGraph = () => {
    if (report.report_type === 'compare')
      return (
        <DataGraph
          data={report.report_response.reports}
          sortBy={report.sort_by}
          type={TableType.COMPARE}
        />
      )

    if (report.report_type === 'analyze') {
      const totals = {
        consumer_segment: {
          name: 'Total (no filters)',
        },
        ...report.report_response.totals,
      }

      return (
        <DataGraph
          data={report.report_response.reports}
          totals={totals}
          sortBy={report.sort_by}
          type={TableType.ANALYZE}
        />
      )
    }

    if (report.report_type === 'trending') {
      return (
        <TrendingGraph
          reports={report.report_response.reports}
          timeFrames={report.report_response.trending_timeframes}
        />
      )
    }

    if (report.report_type === 'compare_geography') {
      const geography = report.report_response.reports[0].geography

      const totals = {
        geography: {
          country: {
            name: `Total no filters (${
              geography.region ? 'Regional' : 'National'
            })`,
          },
        },
        ...report.report_response.totals,
      }
      const metric = METRIC_OPTIONS.find((x) => x.value === report.sort_by)

      return (
        <GeographyMap
          reports={report.report_response.reports}
          totals={totals}
          isCanada={geography.country.slug === 'canada'}
          metric={metric || METRIC_OPTIONS[0]}
        />
      )
    }

    return null
  }

  const renderLoading = () => {
    return (
      <div className="flex h-60 w-full items-center justify-center rounded-md p-4">
        <div className="mr-2 h-5 w-5 animate-spin rounded-full border-2 border-slate-300 border-t-sp-primary" />
        Loading...
      </div>
    )
  }

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center">
        {renderLoading()}
      </div>
    )
  }

  if (!report) return null

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className="text-center text-xl font-bold text-sp-purple">{title}</h1>
      <div className="my-4 w-full">{renderGraph()}</div>
      <ProTip message={report.description || ''} markdown persistent simple />
    </div>
  )
}

export default Report
