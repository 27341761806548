import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { convertRemToPixels } from '../../helpers/utils';

type Props = {
    children: any,
    primary?: boolean,
};

const Carousel: FunctionComponent<Props> = ({ children, primary = false }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [itemWidth, setItemWidth] = useState(0);
    const childRef : any = useRef(null);
    const sliderRef : any = useRef(null);
    const carouselRef : any = useRef(null);

    const nChildren = React.Children.count(children);

    useEffect(() => {
        if (!childRef || !childRef.current || itemWidth > 0) return;
        
        const bcRect = childRef.current.getBoundingClientRect();
        setItemWidth(bcRect.width);
    }, [childRef, itemWidth]);

    const onScroll = (e: any) => {
        if (itemWidth <= 0) return;
        const index = Math.floor((e.target.scrollLeft + window.innerWidth / 2) / itemWidth);

        setActiveIndex(index >= nChildren ? nChildren - 1 : index);
    };

    useEffect(() => {
        if (!sliderRef || !sliderRef.current) return;

        const slideScrollOffset = Math.floor(activeIndex / 6) * sliderRef.current.offsetWidth + (activeIndex < 6 ? 0 : convertRemToPixels(1));
        sliderRef.current.scroll(slideScrollOffset, 0);
    }, [activeIndex]);

	return (
		<div className="flex flex-col">
			<div className="flex flex-row overflow-x-scroll no-scrollbar scroll-smooth" ref={carouselRef} onScroll={onScroll}>
				{React.Children.map(children, (child, index) => {
                    const props = index === 0 ? { ref: childRef, width: "100%" } : {};
					return React.cloneElement(child, props);
				})}
			</div>
            <div className="max-w-[14rem] mx-auto py-5 flex flex-row overflow-x-scroll no-scrollbar scroll-smooth" ref={sliderRef}>
                {React.Children.map(children, (_, index) => (
                    <div
                        className={`flex-none w-6 h-6 rounded-full ${index === activeIndex ? (!primary ? 'bg-sp-primary' : 'bg-sp-green') : 'bg-sp-carouse-navigation'} ${index === 0 ? '' : 'ml-4'}`}
                        /*onClick={() => {
                            const carouselScrollOffset = itemWidth * index - carouselRef.current.offsetWidth / 2;
                            carouselRef.current.scroll(carouselScrollOffset > 0 ? carouselScrollOffset : 0, 0);
                            setActiveIndex(index);
                        }}*/
                    />
                ))}
            </div>
		</div>
    );
};

export default Carousel;
