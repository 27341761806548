import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import { IField } from '../../../@types/hubspot';

type Props = {
    field: IField
}
const FormFieldError: FunctionComponent<Props> = ({ field }) => {
    if (!field.error) return <></>

    return (
        <div className='absolute left-2 md:-translate-y-1.5'>
            <Image
                src='/icons/error.png'
                alt='Error:'
                width={9}
                height={9}
                unoptimized={true}
                className='mr-2'
            />
            <span id={`error-${field.name}`} className='text-xs text-red-500 ml-1'>
                { field.error }
            </span>
        </div>
    );
};

export default FormFieldError;