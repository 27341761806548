import React, {FunctionComponent, useState} from 'react';
import Image from 'next/image';
import ReactPlayer from 'react-player';
import { IVideo } from '../../@types/generated/contentful';

type Props = {
    content: IVideo,
};

const Video: FunctionComponent<Props> = ({content}) => {
    const [isPlaying, setPlay] = useState(false);
    const {url, image} = content.fields;

    const openVideo = () => {
        setPlay(true);
    }

    if (!url || url === undefined) return <></>

    return (
        <div className="mx-auto max-w-4xl py-4">
            <div className="player-wrapper">
                {isPlaying ? (
                    <ReactPlayer
                        className="rounded react-player"
                        url={url}
                        width='100%'
                        height='100%'
                        playing={isPlaying}
                        controls
                    />
                ) : image && !isPlaying ? (
                    <div className="react-player">
                        <div className='absolute top-1/2 right-1/2 -translate-y-1/2 translate-x-1/2 z-10'>
                            <Image
                                src='/icons/play-button.png'
                                className='cursor-pointer'
                                alt='Play Video'
                                width={68}
                                height={68}
                                onClick={() => openVideo()}
                            />
                        </div>
                        <Image
                            src={`https:${image.fields.file.url}`}
                            className="rounded cursor-pointer"
                            alt={image.fields.description}
                            width={image.fields.file.details.image?.width}
                            height={image.fields.file.details.image?.height}
                            objectFit="contain"
                            onClick={() => openVideo()}
                        />
                    </div>
                ) : ""}
            </div>
        </div>
    );
};

export default Video;