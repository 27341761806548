import React, { FunctionComponent, useState } from 'react';
import Image from 'next/image';
import { IMemberTile } from '../../../@types/generated/contentful';

type Props = {
    content: IMemberTile,
    className?: string,
};

const MemberTile: FunctionComponent<Props> = ({ content, className = "" }) => {
    const { name, role, profileImage, hoverImage, profileUrl } = content.fields;
    const [imgSrc, setImgSrc] = useState(profileImage?.fields.file.url || "");

    const onMouseEnter = () => setImgSrc(hoverImage?.fields.file.url || "");
    const onMouseLeave = () => setImgSrc(profileImage?.fields.file.url || "");

    return (
        <div className={className}>
            <div
                className="relative w-full h-72 md:h-96"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <Image
                    src={imgSrc.length ? `https:${imgSrc}` : '/default-profile-image.jpeg'}
                    alt={name}
                    objectFit="cover"
                    layout="fill"
                />
                <div className="absolute bottom-0 inset-x-0 flex flex-row justify-between px-4 md:px-6 py-4 bg-sp-member">
                    <div className="flex flex-col mr-2">
                        <div className="bullet-sub-heading">{name}</div>
                        <div className="body-small mt-1">{role}</div>
                    </div>
                    <a href={profileUrl || "#"} rel='noopener noreferrer' target='_blank'>
                        <Image
                            src='/icons/linkedin_primary.png'
                            alt=''
                            width={32}
                            height={32}
                            objectFit="contain"
                            unoptimized={true}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default MemberTile;