import React, { FunctionComponent } from 'react';
import { IPricingSubscription } from '../../../@types/generated/contentful';
import RichText from '../../richText';
import dynamic from 'next/dynamic';

type Props = {
	content: IPricingSubscription
};

const CallToAction = dynamic(() => import('../../callToAction'), {
    ssr: false,
  })

const PricingSubscription: FunctionComponent<Props> = ({ content }) => {
    const { popular, subscriptionName, description, price, shortDescription, ctaButton, features, period } = content.fields;

    const btnCTA = ctaButton
        ? <CallToAction key={ctaButton.sys.id} content={ctaButton.fields} callback={() => {}} className='font-normal md:w-full' />
        : <></>;

    return (
		<div className={`h-full ${popular ? 'rounded-b' : 'rounded'} border border-sp-primary flex flex-col px-6 py-8 relative`}>
            <div className="absolute inset-0 grid grid-rows-2 z-0">
                <div className="bg-sp-light-purple row-span-1 rounded" />
                <div className="bg-white row-span-1 rounded" />
            </div>
			<div className="min-h-[20rem] md:min-h-[22rem] flex flex-col items-center z-10">
				<span className="extra-large-label font-bold text-center">{subscriptionName.toUpperCase()}</span>
				<span className="body-medium text-center mt-4">{description}</span>
                <div className="flex flex-col my-auto">
                    {price !== undefined ? (
                        <div className="flex flex-row mt-6 mb-2">
                            <span className="medium-label self-start">$</span>
                            <span className="large-label font-normal mx-1">{price}</span>
                            <span className="medium-label self-end">/</span>
                            <span className="medium-label self-end">{period}</span>
                        </div>
                    ) : ''}
                    {shortDescription ? (
                        <span className="body-extra-small text-center">{shortDescription}</span>
                    ) : ''}
                </div>
				<div className="mt-6 w-full">
					{ btnCTA }
				</div>
			</div>
			<div className="flex-1 mt-4 z-10">
                {features ? (
                    <RichText content={features}/>
                ) : ''}
			</div>
		</div>
    );
};

export default PricingSubscription;
