import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import { IHomeDifference } from '../../../@types/generated/contentful';
import RichText from '../../richText';

type Props = {
    content: IHomeDifference,
};

const HomeDifference: FunctionComponent<Props> = ({ content }) => {
    const { seoCopy, title, body, features, background } = content.fields;

    return (
        <div className="container mx-auto grid grid-cols-12 px-0 py-6 md:p-8">
            <div className="col-span-12 md:col-span-4 rounded-tr-sp-home-difference rounded-br mr-2 md:mr-10 py-4" style={{backgroundColor: background}}>
                <div className="w-3/12 md:w-8/12 h-full flex items-center">
                    <Image
                        src='/sponsorpulse-logo-white-half.png'
                        alt='SponsorPulse'
                        width={450}
                        height={771}
                        objectFit="contain"
                    />
                </div>
            </div>
            <div className="flex flex-col col-span-12 md:col-span-6 p-6 md:py-4">
                <div className="seo-copy">{seoCopy}</div>
                <h2 className="my-1">{title}</h2>
                <div className="body-large mt-4">{body}</div>
                <div className="-ml-2 md:-ml-12 mt-6 md:mt-10 lg:mt-12">
                    <RichText content={features} />
                </div>
            </div>
        </div>
    );
};

export default HomeDifference;
