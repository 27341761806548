import React, { FunctionComponent } from 'react'
import { LINE_COLORS } from '../../constants/trending'
import PropertyImage from '../propertyImage'

interface ITrendingLegend {
  /**
   * Property code
   */
  propertyCode: string
  /**
   * Property name for alt text
   */
  propertyName?: string
  /**
   * Index
   */
  legendIndex: number
  /**
   * Selection status
   */
  selected: boolean
  /**
   * disabled
   */
  disabled?: boolean
  /**
   * Callback
   */
  callback: Function
}

export const TrendingLegend: FunctionComponent<ITrendingLegend> = ({
  propertyCode,
  propertyName = '',
  legendIndex,
  selected,
  disabled = false,
  callback,
}) => {
  return (
    <div
      className={`flex h-24 w-44 cursor-pointer flex-col items-center rounded-md border border-sp-light-grey px-4 py-2 ${
        disabled
          ? '!cursor-not-allowed bg-neutral-300'
          : selected
          ? 'bg-sp-light-blue'
          : 'bg-white'
      }`}
      onClick={() => {
        if (disabled) return
        callback()
      }}
    >
      <div
        className="h-1 w-full"
        style={{ backgroundColor: LINE_COLORS[legendIndex] }}
      />
      <div className="mt-1 flex w-full flex-1 items-center justify-center gap-2">
        <PropertyImage
          propertyCode={propertyCode}
          propertyName={propertyName}
          size={30}
        />
        <span className="text-wrap text-center text-sm leading-none">
          {propertyName}
        </span>
      </div>
    </div>
  )
}
