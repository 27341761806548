import React, { FunctionComponent } from 'react';
import { IPricingFocus } from '../../../@types/generated/contentful';
import PricingSubscription from '../pricingSubscription';
import Carousel from '../../carousel';

type Props = {
	content: IPricingFocus
};

const PricingFocus: FunctionComponent<Props> = ({ content }) => {
	const { subscriptions } = content.fields;

    return (
		<div className="container mx-auto p-6 md:px-8 md:py-12 lg:px-10 lg:py-16">
            <div className="block xl:hidden">
                <Carousel>
                    {subscriptions.map((subscription, index) => (
                        <div
                            key={subscription.sys.id}
                            className={`w-72 flex-none ${index === 0 ? 'm-0' : 'ml-4' } flex flex-col`}
                        >
                            {subscription.fields.popular ? (
                                <div className="body-medium h-10 bg-sp-primary px-4 py-2 rounded-t-lg text-white w-full text-center flex items-center justify-center">
                                    Most popular
                                </div>
                            ) : (
                                <div className="h-10"></div>
                            )}
                            <div className="flex-1">
                                <PricingSubscription content={subscription} />
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
            <div className="hidden xl:grid grid-cols-12">
                {subscriptions.map((subscription, index) => (
                    <div
                        key={subscription.sys.id}
                        className={`col-span-4 ${index === 0 ? 'm-0' : 'ml-2' } flex flex-col`}
                    >
                        {subscription.fields.popular ? (
                            <div className="body-medium h-10 bg-sp-primary px-4 py-2 rounded-t-lg text-white w-full text-center flex items-center justify-center">
                                Most popular
                            </div>
                        ) : (
                            <div className="h-10"></div>
                        )}
                        <div className="flex-1">
                            <PricingSubscription content={subscription} />
                        </div>
                    </div>
                ))}
            </div>
		</div>
    );
};

export default PricingFocus;
