import React, { FunctionComponent } from 'react'
import Link from 'next/link'
import { ICallToActionFields } from '../@types/generated/contentful'
import {
  isExternal,
  isPlatform,
  getQueryString,
} from '../helpers/urlValidation'
import { UTM } from '../services/utm'
import { clearQuery, sendEvent } from '../helpers/analytics'

type Props = {
  content: ICallToActionFields
  callback: Function
  className?: string
  header?: boolean
}

const CallToAction: FunctionComponent<Props> = ({
  content,
  callback,
  className = '',
  header = false,
}) => {
  const handleCallback = () => {
    callback()

    clearQuery()
    sendEvent('High Value Click', {
      link: content.url,
      text: content.title,
    })
  }

  let style = ''
  switch (content.style) {
    case 'Primary':
      style =
        'button inline-block w-full rounded text-center bg-sp-primary md:w-fit text-white px-6 py-2'
      break
    case 'Secondary':
      style =
        'button inline-block w-full rounded text-center bg-white text-sp-primary border-sp-primary md:w-fit border px-6 py-2'
      break
    case 'Link':
      style =
        'button inline-block w-full rounded text-left text-sp-primary underline md:h-auto md:w-fit'
      break
  }
  if (style.length === 0) {
    if (header) {
      style = content.primary
        ? 'inline-block w-full h-12 lg:h-10 rounded text-center font-base py-2 mb-4 lg:mb-0 bg-sp-primary lg:w-44 text-white'
        : 'inline-block w-full h-12 lg:h-10 rounded text-center font-base py-2 mb-4 lg:mb-0 border border-sp-primary lg:border-0 text-sp-primary lg:text-sp-purple lg:w-auto'
    } else {
      if (content.primary)
        style =
          'button inline-block w-full rounded text-center bg-sp-primary md:w-fit text-white px-6 py-2'
      else if (content.border)
        style =
          'button inline-block w-full rounded text-center bg-white text-sp-primary border-sp-primary md:w-fit border px-6 py-2'
      else
        style =
          'button inline-block w-full rounded text-left md:text-center text-sp-primary md:h-auto'
    }
  }
  let queryString = ``
  const utm = new UTM()
  if (isExternal(content.url)) {
    if (isPlatform(content.url)) {
      queryString = getQueryString(content.url, utm)
    }

    return (
      <a
        href={`${content.url}${queryString}`}
        rel={isPlatform(content.url) ? '' : 'noopener noreferrer'}
        target="_blank"
        className={`${style} ${className}`}
        onClick={() => handleCallback()}
      >
        {content.title}
      </a>
    )
  }

  return (
    <Link href={content.url}>
      <a className={`${style} ${className}`} onClick={() => handleCallback()}>
        {content.title}
      </a>
    </Link>
  )
}

export default CallToAction
