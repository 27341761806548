import React, { FunctionComponent, useState, useEffect } from 'react';
import { ISearchPropertySection } from '../../../@types/generated/contentful';
import TitleBrick from '../../titleBrick';
import SearchPropertyInput from '../searchPropertyInput';
import { fetchProperties } from '../../../services/api';

type Props = {
    content: ISearchPropertySection,
};

const SearchPropertySection: FunctionComponent<Props> = ({ content }) => {
	const [properties, setProperties] = useState<Object[]>([]);
	const { titleBrick, searchText } = content.fields;

	useEffect(() => {
		loadProperties();
	}, []);

	const loadProperties = async () => {
		const properties = await fetchProperties();
		setProperties(properties);
	}

    return (
        <div className="flex flex-col">
			<TitleBrick content={titleBrick} />
			<div style={{backgroundColor: titleBrick.fields.background || "white"}}>
				<div className="container mx-auto w-full md:w-fit flex items-center -mt-8 p-6 md:px-8 md:pt-0 md:pb-8">
					<SearchPropertyInput searchText={searchText || ""} properties={properties} />
				</div>
			</div>
        </div>
    );
};

export default SearchPropertySection;
