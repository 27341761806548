export async function fetchProperties() {
	const response = await fetch(`${process.env.NEXT_PUBLIC_PROPERTIES_API!}/properties`, {
		method: 'GET',
		redirect: 'follow'
	});
	if (!response.ok) return [];

	return response.json();
}

export async function fetchPropertyBySlug(slug: string) {
	const response = await fetch(`${process.env.NEXT_PUBLIC_PROPERTIES_API!}/properties/${slug}`, {
		method: 'GET',
		redirect: 'follow'
	});
	if (!response.ok) return {};

	return response.json();
}

export async function fetchPropertyReport(slug: string) {
	const response = await fetch(`${process.env.NEXT_PUBLIC_PROPERTIES_API!}/free_property_reports/${slug}`, {
		method: 'GET',
		redirect: 'follow'
	});
	if (!response.ok) return {};

	return response.json();
}

export async function fetchPermalinkReport(permalink_id: string) {
	const response = await fetch(`${process.env.NEXT_PUBLIC_API!}/v2/reports/permalinks/${permalink_id}`, {
		method: 'GET',
		redirect: 'follow'
	});
	if (!response.ok) return {};

	return response.json();
}
