import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import ReactMarkdown from 'react-markdown';
import { ICareerSection } from '../../../@types/generated/contentful';
import dynamic from 'next/dynamic'
type Props = {
    content: ICareerSection,
};

const CallToAction = dynamic(() => import('../../callToAction'), {
    ssr: false,
  })

const CareerSection: FunctionComponent<Props> = ({ content }) => {
    const { seoCopy, title, body, ctaButton, image, mobileImage, background } = content.fields;

    const btnCTA = ctaButton
        ? <CallToAction content={ctaButton.fields} callback={() => {}}/>
        : <></>;

    return (
        <div style={{backgroundColor: background}} className="pt-6 pb-4 md:pt-0 md:pb-12">
            <div className="relative lg:h-[68rem] container mx-auto px-6 md:px-8">
                <div className="hidden lg:block">
                    {image ? (
                        <Image
                            src={`https:${image.fields.file.url}`}
                            alt={image.fields.description}
                            objectFit="contain"
                            objectPosition="bottom"
                            layout="fill"
                        />
                    ) : ""}
                </div>
                <div className="mx-auto w-full lg:w-7/12 xl:w-1/2 flex flex-col items-center p-0 md:pt-12">
                    <div className="seo-copy">{seoCopy}</div>
                    <h2 className="text-center mb-2 md:mb-4">{title}</h2>
                    <ReactMarkdown className="large-markdown text-center z-10">{body || ""}</ReactMarkdown>
                    <div className="mt-4 w-full md:w-auto z-10">
                        { btnCTA }
                    </div>
                    {mobileImage ? (
                        <div className="block lg:hidden mt-10">
                            <Image
                                src={`https:${mobileImage.fields.file.url}`}
                                alt={mobileImage.fields.description}
                                width={mobileImage.fields.file.details.image?.width}
                                height={mobileImage.fields.file.details.image?.height}
                                objectFit="contain"
                            />
                        </div>
                    ) : ""}
                </div>
            </div>
        </div>
    );
};

export default CareerSection;
