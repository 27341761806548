import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Asset } from 'contentful';
import { friendlyDate } from '../../../helpers/dateTime';

type Props = {
    image: Asset,
    date: string,
    title: string,
    slug: string,
    index: number,
};

const HomeBlogPostTile: FunctionComponent<Props> = ({ image, date, title, slug, index }) => {

    return (
        <Link href={`/insights/${slug}`}>
            <a className={`bg-white col-span-12 ${index === 0 ? "md:col-span-7 row-span-2" : "md:col-span-5"} my-2 md:m-2`}>
                <div className="flex flex-col rounded shadow-lg md:h-full">
                    <Image
                        src={`https:${image.fields.file.url}`}
                        alt={image.fields.description}
                        width={index === 0 ? 1007 : 764}
                        height={index === 0 ? 903 : 350}
                        layout="responsive"
                        objectFit="cover"
                    />
                    <div className="flex flex-col justify-center p-4 flex-1">
                        <div className="py-4">
                            <div className="seo-copy uppercase mb-2">{friendlyDate(date)}</div>
                            <p className={`${index === 0 ? "sub-heading-large" : "sub-heading-medium"} text-sp-purple`}>
                                {title}
                            </p>
                        </div>
                        <div className="mt-2">
                            <Image
                                src='/icons/chevron-right.png'
                                alt="chevron"
                                width={40}
                                height={40}
                            />
                        </div>
                    </div>
                </div>
            </a>
        </Link>
    );
};

export default HomeBlogPostTile;