import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import { IFaqTile } from '../../@types/generated/contentful';

type Props = {
    content: IFaqTile,
    opened: boolean,
    onOpen: Function,
};

const FaqTile: FunctionComponent<Props> = ({ content, onOpen, opened = false }) => {
    const { question, answer } = content.fields;

    return (
        <div className="flex flex-col py-4 border-b cursor-pointer" onClick={() => onOpen(!opened)}>
            <div className="flex justify-between">
                <div className="flex-1 sub-heading-medium">{question}</div>
                <Image
                    src="/icons/chevron_down.svg"
                    className={`${opened ? "rotate-180" : "rotate-0"} transition linear duration-500`}
                    alt=""
                    width={28}
                    height={16}
                    objectFit="contain"
                />
            </div>
            {opened ? (
                <div className="body-small mt-4">{answer}</div>
            ) : ""}
        </div>
    );
};

export default FaqTile;