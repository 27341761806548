import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import { BLOCKS, INLINES, MARKS, Node } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IRichText } from '../../@types/generated/contentful';

type Props = {
  content: IRichText
};

const RichTextPage: FunctionComponent<Props> = ({ content }) => {
  return (
    <div className='container my-6 px-4 md:mx-auto font-light'>
        <h1 className='mb-5 text-center'>{content.fields.title}</h1>
        {documentToReactComponents(content.fields.content,options)}
    </div>
  );
};

const Bold: FunctionComponent = ({ children }) => <span className="font-bold">{children}</span>;
const Text: FunctionComponent = ({ children }) => <p className="text-left py-2">{children}</p>;
const Heading1: FunctionComponent = ({ children }) => <h1 className="text-left py-4">{children}</h1>;
const Heading2: FunctionComponent = ({ children }) => <h2 className="text-left py-4">{children}</h2>;
const Heading3: FunctionComponent = ({ children }) => <h3 className="text-left py-4">{children}</h3>;
const Heading4: FunctionComponent = ({ children }) => <h4 className="text-left py-4">{children}</h4>;
const Heading5: FunctionComponent = ({ children }) => <h5 className="text-left py-4">{children}</h5>;
const Heading6: FunctionComponent = ({ children }) => <h6 className="text-left py-4">{children}</h6>;
const UnorderedList: FunctionComponent = ({ children }) => <ul className="text-left ml-6 md:ml-16 list-disc">{children}</ul>;
const ListItem: FunctionComponent = ({ children }) => <li className="">{children}</li>;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text: any) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node: Node, children: any) => <Heading1>{children}</Heading1>,
    [BLOCKS.HEADING_2]: (node: Node, children: any) => <Heading2>{children}</Heading2>,
    [BLOCKS.HEADING_3]: (node: Node, children: any) => <Heading3>{children}</Heading3>,
    [BLOCKS.HEADING_4]: (node: Node, children: any) => <Heading4>{children}</Heading4>,
    [BLOCKS.HEADING_5]: (node: Node, children: any) => <Heading5>{children}</Heading5>,
    [BLOCKS.HEADING_6]: (node: Node, children: any) => <Heading6>{children}</Heading6>,
    [BLOCKS.UL_LIST]: (node: Node, children: any) => <UnorderedList>{children}</UnorderedList>,
    [BLOCKS.LIST_ITEM]: (node: Node, children: any) => <ListItem>{children}</ListItem>,
    [INLINES.HYPERLINK]: ({ data }:any, children: any) => (
        <a
            href={data.uri}
            className='underline'
            target={`${data.uri.startsWith(process.env.NEXT_PUBLIC_CANONICAL_URL) ? '_self' : '_blank'}`}
            rel={`${data.uri.startsWith(process.env.NEXT_PUBLIC_CANONICAL_URL) ? '' : 'noopener noreferrer'}`}
        >{children}</a>
      ),
    [BLOCKS.EMBEDDED_ASSET]: (node: Node) => (
      <div className="my-4 md:my-8 h-36 md:h-96 relative">
        <Image
          src={`https:${node.data?.target?.fields?.file?.url}`}
          className="py-4"
          alt={node.data?.target?.fields?.title}
          layout="fill"
          objectFit='contain'
        />
      </div>
    ),
    [BLOCKS.PARAGRAPH]: (node: Node, children: any) => <Text>{children}</Text>,
  },
  renderText: (text: string) => {
    return text.split('\n').reduce((children: React.ReactNode[], textSegment: string, index: number) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

export default RichTextPage;