import { createClient } from 'contentful';
import { CONTENT_TYPE_BLOG, CONTENT_TYPE_PAGE, CONTENT_TYPE_BLOG_TAG, CONTENT_TYPE_HEADER, CONTENT_TYPE_FOOTER, CONTENTFUL_PAGE_LIMIT, CONTENT_TYPE_CAREER_POSTING, CONTENT_TYPE_LANDING_PAGE, CONTENT_TYPE_MARKETING_MODAL } from './constants';

export class ContentfulService {
	private client = createClient({
		space: process.env.CONTENTFUL_SPACE_ID!,
		accessToken: process.env.CONTENTFUL_CONTENT_ACCESS_TOKEN!,
		environment: process.env.CONTENTFUL_ENVIRONMENT!
	});

	async fetchEntryBySlug(slug: string, content_type: string) {
		return await this.client.getEntries({
			content_type: content_type,
			'fields.slug': slug,
			include: 4
		});
	}

	async fetchBlogPostsByTag(id: string,page: number) {
		return await this.client.getEntries({
			content_type: CONTENT_TYPE_BLOG,
			'fields.platformOnly[ne]': 'true',
			select: 'sys.id,fields.title,fields.slug,fields.image,fields.date',
			links_to_entry: id,
			order: '-fields.date',
			skip: page*CONTENTFUL_PAGE_LIMIT,
			limit: CONTENTFUL_PAGE_LIMIT,
			include: 2
		});
	}

	async fetchBlogPosts(page: number) {
		return await this.client.getEntries({
			content_type: CONTENT_TYPE_BLOG,
			select: 'sys.id,fields.title,fields.slug,fields.image,fields.date',
			'fields.platformOnly[ne]': 'true',
			order: '-fields.date',
			skip: page*CONTENTFUL_PAGE_LIMIT,
			limit: CONTENTFUL_PAGE_LIMIT,
			include: 2
		});
	}

	async fetchBlogTags() {
		return await this.client.getEntries({
			content_type: CONTENT_TYPE_BLOG_TAG,
			'fields.platformTag[ne]': 'true',
			order: 'fields.title'
		});
	}
  
	async fetchRelatedBlogPosts(tags: string[], slug: string) {
		return await this.client.getEntries({
			content_type: CONTENT_TYPE_BLOG,
			links_to_entry: tags[0],
			'fields.slug[nin]': slug,
			'fields.platformOnly[ne]': 'true',
			select: 'sys.id,fields.title,fields.slug,fields.image,fields.date',
			order: '-fields.date',
			limit: 3,
			include: 2
		});
	}

	async fetchAllPages() {
		return await this.client.getEntries({
			content_type: CONTENT_TYPE_PAGE,
			select: 'fields.slug',
			include: 1
		});
	}

	async fetchAllBlogPosts() {
		return await this.client.getEntries({
			content_type: CONTENT_TYPE_BLOG,
			select: 'fields.slug',
			include: 1
		});
	}

	async fetchAllCareerPosts() {
		return await this.client.getEntries({
			content_type: CONTENT_TYPE_CAREER_POSTING,
			select: 'sys.id,fields.title,fields.slug,fields.image,fields.date',
			order: '-fields.date',
			include: 1
		});
	}

	async fetchAllLandingPages() {
		return await this.client.getEntries({
			content_type: CONTENT_TYPE_LANDING_PAGE,
			select: 'fields.slug',
			include: 1
		});
	}

	async fetchAllMarketingModals() {
		return await this.client.getEntries({
			content_type: CONTENT_TYPE_MARKETING_MODAL,
			select: 'sys.id,fields.image,fields.title,fields.body,fields.cta,fields.campaign,fields.triggerAfter',
			include: 1
		});
	}

	async getEntryBySlug(slug: string, content_type: string) {
		try {
			const content: any = await this.fetchEntryBySlug(slug, content_type);
			const entry: { sys: any; fields: any } = content.items[0];

			return entry;
		} catch (error) {
			console.error(error);
		}
	}

	async getBlogPosts(id: string, page=0 ) {
		try {
			const content = (id === 'undefined') ? await this.fetchBlogPosts(page) : await this.fetchBlogPostsByTag(id,page);
			const entries = content.items;

			return entries;
		} catch (error) {
			console.error(error);
		}
	}

	async getBlogTags() {
		try {
			const content: any = await this.fetchBlogTags();
			const entries = content.items;

			return entries;
		} catch (error) {
			console.error(error);
		}
	}	

	async getRelatedBlogPosts(tags: string[], slug: string) {
		try {
			const content: any = await this.fetchRelatedBlogPosts(tags, slug);
			const entries = content.items;

			return entries;
		} catch (error) {
			console.error(error);
		}
	}

	async getAllPages() {
		try {
			const content: any = await this.fetchAllPages();
			const entries = content.items;

			return entries;
		} catch (error) {
			console.error(error);
		}
	}

	async getAllBlogPosts() {
		try {
			const content: any = await this.fetchAllBlogPosts();
			const entries = content.items;

			return entries;
		} catch (error) {
			console.error(error);
		}
	}

	async getAllCareerPosts() {
		try {
			const content: any = await this.fetchAllCareerPosts();
			const entries = content.items;

			return entries;
		} catch (error) {
			console.error(error);
		}
	}

	async getFooter() {
		try {
			const content: any = await this.fetchEntryBySlug("main", CONTENT_TYPE_FOOTER);
			const entry: { sys: any; fields: any } = content.items[0];

			return entry;
		} catch (error) {
			console.error(error);
		}
	}

	async getHeader() {
		try {
			const content: any = await this.fetchEntryBySlug("main", CONTENT_TYPE_HEADER);
			const entry: { sys: any; fields: any } = content.items[0];

			return entry;
		} catch (error) {
			console.error(error);
		}
	}

	async getTag(tag: string) {
		try {
			const content: any = await this.fetchEntryBySlug(tag, CONTENT_TYPE_BLOG_TAG);
			const entry: { sys: any; fields: any } = content.items[0];

			return entry;
		} catch (error) {
			console.error(error);
		}
	}

	async getAllLandingPages() {
		try {
			const content: any = await this.fetchAllLandingPages();
			const entries = content.items;

			return entries;
		} catch (error) {
			console.error(error);
		}
	}

	async getMarketingModals() {
		try {
			const content: any = await this.fetchAllMarketingModals();
			const entries = content.items;

			return entries;
		} catch (error) {
			console.error(error);
		}
	}	
}
