import { Asset } from 'contentful';
import { IMetaFields } from '../@types/generated/contentful';
import { IPropertyGraph } from '../@types/global';

// CONTENTFUL CONSTANTS
export const CONTENT_TYPE_PAGE = 'page';
export const CONTENT_TYPE_BLOG = 'blog';
export const CONTENT_TYPE_BLOG_TAG = 'blogTag';
export const CONTENT_TYPE_HEADER = 'header';
export const CONTENT_TYPE_FOOTER = 'footer';
export const CONTENT_TYPE_CAREER_POSTING = 'careerPosting';
export const CONTENT_TYPE_LANDING_PAGE = 'landingPage';
export const CONTENT_TYPE_MARKETING_MODAL = 'marketingModal';
export const CONTENTFUL_PAGE_LIMIT = 12;

// HUBSPOT CONSTANTS
export const HUBSPOT_FORM_NOT_FOUND = 'Form not found';
export const HUBSPOT_GENERIC_ERROR = 'Someting went wrong'

// FORM VALIDATION
export const REQUIRED_FIELD = ' is required'
export const INVALID_EMAIL = 'Please enter a valid email address'

// SLACK CONSTANTS
export const INBOUND_SLACK_CHANNEL = 'inbound';

enum RobotsContent {
    follow = 'follow',
    index = 'index',
    no_follow = 'nofollow',
    no_index = 'noindex'
}

enum PageType {
    website = 'website',
    article = 'article'
}

export const defaultMetaTags: IMetaFields = {
    title: 'Sponsorship decisions, simplified',
    description: 'Sponsorship Decisions, Simplified. How We Simplify Your Sponsorship Marketing Journey With Global Consumer Insights. Get Access To A Free Sponsorship Opportunity Report And Start Your Free Trial Today.',
    pageType: PageType.website,
    shareImage: <Asset>{
        fields: {
            file: {
                url: '/shareImage.png'
            }
        }
    },
    robotsIndex: RobotsContent.index,
    robotsFollow: RobotsContent.follow,
};

export const FREE_PROPERTY_GRAPHS: IPropertyGraph[] = [
    {
        title: "TOTAL REACH",
        tooltip: "Total annual property reach",
        attributes: [
            {
                title: "Engagement",
                attribute: "engagement",
                show_info: true,
                icon: "ic_engagement"
            }
        ]
    },
    {
        title: "BEHAVIOR & INTEREST",
        description: "AMONG ENGAGED",
        tooltip: "Intensity is the % of those who engage that do so weekly or more, while momentum is the % of those who engage with a growing interest in the property.",
        attributes: [
            {
                title: "Intensity",
                attribute: "intensity",
                icon: "ic_intensity"
            },
            {
                title: "Momentum",
                attribute: "momentum",
                icon: "ic_momentum"
            }
        ]
    },
    {
        title: "EMOTIONAL CONNECTION",
        description: "AMONG ENGAGED",
        tooltip: "Identify the % of those who engage who are excited about the property today, relative to the % that are passionate about the property.",
        attributes: [
            {
                title: "Excitement",
                attribute: "excitement",
                icon: "ic_excitement"
            },
            {
                title: "Passion",
                attribute: "passion",
                icon: "ic_passion"
            }
        ]
    },
    {
        title: "POTENTIAL SPONSOR IMPACT",
        description: "AMONG ENGAGED",
        tooltip: "Benchmark your expectations and performance against the % of those who engage who state they’d be impacted by a brand sponsorship.",
        attributes: [
            {
                title: "Consideration",
                attribute: "purchase_consideration",
                icon: "ic_purchase"
            },
            {
                title: "Favorability",
                attribute: "favorability",
                icon: "ic_favorability"
            }
        ]
    }
];

export const menuItems = [
    {
        title: "Solutions",
        slug: "solutions",
    },
    {
        title: "Pricing",
        slug: "pricing",
    },
    {
        title: "Insights",
        slug: "insights",
    },
    {
        title: "About",
        slug: "about",
    },
]

export const menuCTAs = [
    {
        primary: false,
        title: "Login",
        url: "https://app.sponsorpulse.com"
    },
    {
        primary: true,
        title: "Join for Free",
        url: "https://app.sponsorpulse.com/sign_up"
    }
]