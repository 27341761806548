import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import { ISolutionTile } from '../../@types/generated/contentful';
import RichText from '../richText';
import dynamic from 'next/dynamic';

type Props = {
    content: ISolutionTile,
    className: string,
};

const CallToAction = dynamic(() => import('../callToAction'), {
    ssr: false,
})

const SolutionTile: FunctionComponent<Props> = ({ content, className }) => {
    const { title, text, richText, image, ctaButton } = content.fields;

    const btnCTA = ctaButton
        ? <CallToAction key={ctaButton.sys.id} content={ctaButton.fields} callback={() => {}}/>
        : <></>
    
    return (
        <div className={`flex flex-row md:flex-col items-start md:items-center ${className}`}>
            <div className="relative hidden md:block w-20 h-20 xl:w-40 xl:h-40 flex items-center justify-center">
                {image ? (
                    <Image
                        src={`https:${image.fields.file.url}`}
                        alt={image.fields.description}
                        layout="fill"
                        objectFit="cover"
                    />
                ) : ("")}
            </div>
            <div className="flex flex-col items-start md:items-center mt-0 ml-0 md:mt-4 flex-1 md:w-full">
                <div className="sub-heading-small text-left md:text-center">{title}</div>
                <div className="body-medium text-left md:text-center mt-2 md:mb-4">
                    {(richText) ? <RichText content={richText}/> : text}
                </div>
                <div className="w-full md:w-fit">
                    { btnCTA }
                </div>
            </div>
        </div>
    );
};

export default SolutionTile;