import React, { FunctionComponent } from 'react';
import { ICodeBlock } from '../../@types/generated/contentful';

type Props = {
  content: ICodeBlock
};

const CodeBlock: FunctionComponent<Props> = ({ content }) => {
  return (
    <div className='py-4' dangerouslySetInnerHTML={{ __html: content.fields.code! }} />
  );
};

export default CodeBlock;