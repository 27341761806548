import { IOption } from "../@types/common";

export const METRIC_OPTIONS: IOption[] = [
  {
    value: 'consideration',
    label: 'Consideration',
  },
  {
    value: 'engagement',
    label: 'Engagement',
  },
  {
    value: 'excitement',
    label: 'Excitement',
  },
  {
    value: 'favorability',
    label: 'Favourability',
  },
  {
    value: 'daily_weekly',
    label: 'Intensity',
  },
  {
    value: 'momentum',
    label: 'Momentum',
  },
  {
    value: 'opportunity_score',
    label: 'Opportunity Score',
  },
  {
    value: 'passion',
    label: 'Passion',
  },
]

export const SKIP_REGIONS = ['ontario', 'quebec']; // skip rendering of state provinces in these regions
