import React, { FunctionComponent, useState } from 'react';
import { IField } from "../../@types/hubspot";
import { validateField } from '../../helpers/formValidation';
import { UTM } from '../../services/utm'

import FormFieldError from '../form/fieldError';
import Spinner from '../spinner';

const NewsletterForm: FunctionComponent = () => {
    const [email,setEmail] = useState<IField>({
        objectTypeId: "",
        name: "email",
        label: "Email",
        required: true,
        hidden: false,
        fieldType: 'email',
        value: '',
        error: ''
    });
    const [isSubmitting,setSubmitting] = useState(false);
    const [isComplete,setCompleted] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let field = { ...email }
        let submission = {
            fields: {},
            context: {
                pageUri: window.location.href,
                pageName: document.title
            }
        };
        field.error = validateField(email)
        
        if (field.error) {
            setEmail(field)
        } else {
            setSubmitting(true);
            const utm = new UTM();
            submission.fields = { email: email.value, ...utm.getUtm() }
            fetch(`/api/form/${process.env.NEXT_PUBLIC_NEWSLETTER_FORM}`,{
                method: 'post',
                headers: new Headers({'content-type': 'application/json'}),
                body: JSON.stringify(submission)
            })
                .then((res) => {
                    if (!res.ok) throw res;
                    return res.json();
                })
                .then(() => {
                    setSubmitting(false);
                    setCompleted(true);
                    utm.clearUtm();
                })
                .catch((error) => {
                    setSubmitting(false);
                    error.json().then((body:any) => {
                        field.error = body.error;
                        setEmail(field)
                    });
                });
        }
    }

    const handleUpdate = (value : string) => {
        let field = { ...email };
        field.value = value;
        setEmail(field);
    }

    return (
        <div>
            <form id="newsletter-footer" className={`grid grid-cols-2 md:grid-cols-12 ${(isComplete) ? 'hidden' : ''}`} onSubmit={handleSubmit} noValidate>
                <div className="relative md:col-span-6 lg:col-span-8 pl-4 md:px-0">
                    <input className={`${email.error ? 'bg-sp-error-red border border-red-500' : 'bg-white'} w-full h-11 appearance-none rounded py-2 px-2 text-gray-700 leading-tight`} type="email" placeholder='Your email address' onChange={(event) => handleUpdate(event.target.value)}/>
                    <FormFieldError field={email} />
                </div>
                <div className="md:col-span-6 lg:col-span-4 px-4 md:pr-0">
                    <button className='bg-sp-primary w-full h-11 rounded leading-10 text-lg text-white font-light px-2'>
                    { (isSubmitting) ? <div className='inline-block w-4 h-4'><Spinner /></div> : 'Subscribe'} 
                    </button>
                </div>
            </form>
            <div className={`${(isComplete) ? '':'hidden '} text-lg h-11`}>
                {"You're subscribed!"}
            </div>
        </div>
    );
};

export default NewsletterForm;