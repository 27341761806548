import React, { FunctionComponent, useState } from 'react'
import { geoCentroid } from 'd3-geo'
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from 'react-simple-maps'
import ReactTooltip from 'react-tooltip'
import { METRIC_OPTIONS, SKIP_REGIONS } from '../../constants/app'
import {
  CANADA_STATES,
  CANADA_OFFSETS,
  USA_STATES,
  USA_OFFSETS,
} from '../../constants/geography'
import {
  getGeographyColorPalette,
  isOpportunityScore,
} from '../../helpers/utils'
import { IOption } from '../../@types/common'

interface IProps {
  /**
   * Report data
   */
  reports: any[]
  /**
   * Totals
   */
  totals: any
  /**
   * canada or united states
   */
  isCanada: boolean
  /**
   * Metric
   */
  metric: IOption
}

export const GeographyMap: FunctionComponent<IProps> = ({
  reports = [],
  totals,
  isCanada = false,
  metric,
}) => {
  const [tooltipReport, setTooltipReport] = useState<any>(null)

  const geoJson = isCanada
    ? '/json/canada-states.json'
    : '/json/usa-states.json'
  const geoStates = isCanada ? CANADA_STATES : USA_STATES
  const geoOffsets = isCanada ? CANADA_OFFSETS : USA_OFFSETS

  const renderTip = () => {
    const { label, report } = tooltipReport

    if (!tooltipReport) return null

    return (
      <div className="flex flex-col items-center">
        <p className="text-sm font-bold md:text-lg">{label}</p>
        {METRIC_OPTIONS.sort((x, y) => {
          if (x.value === metric.value) return -1
          if (y.value === metric.value) return 1
          return 0
        }).map((metric) => {
          if (!report) return null

          return (
            <span key={metric.value} className="text-xs md:text-base">
              {metric.label}: {report[metric.value]}
              {!isOpportunityScore(metric.value as string) ? '%' : ''}
            </span>
          )
        })}
      </div>
    )
  }

  return (
    <div className="relative my-2 bg-white p-2">
      <div data-tip="">
        <ComposableMap
          projection={isCanada ? 'geoAlbers' : 'geoAlbersUsa'}
          projectionConfig={
            isCanada
              ? {
                  center: [3, 62],
                  scale: 600,
                }
              : { scale: 600 }
          }
          width={800}
          height={400}
        >
          <Geographies geography={geoJson}>
            {({ geographies }) => (
              <>
                {geographies.map((geo) => {
                  const report = reports.find((x) => {
                    if (
                      !x.geography ||
                      !x.geography.country ||
                      !x.geography.region ||
                      !x.geography.state_province
                    )
                      return false

                    return (
                      x.geography.state_province.name === geo.properties.name
                    )
                  })

                  return (
                    <Geography
                      key={geo.rsmKey}
                      stroke="#FFF"
                      geography={geo}
                      onMouseEnter={() => {
                        setTooltipReport({
                          label: geo.properties.name,
                          report,
                        })
                      }}
                      onMouseLeave={() => {
                        setTooltipReport(null)
                      }}
                      style={{
                        default: {
                          fill: report
                            ? getGeographyColorPalette(
                                metric.value === 'opportunity_score'
                                  ? report[metric.value] /
                                      reports[0][metric.value]
                                  : report[metric.value] / 100
                              )
                            : '#DDD',
                          outline: 'none',
                        },
                        hover: {
                          fill: '#b3a9ef',
                          outline: 'none',
                        },
                      }}
                    />
                  )
                })}
                {geographies.map((geo) => {
                  const centroid = geoCentroid(geo)
                  const cur = geoStates.find((s) => s.val === geo.id)

                  const geoLimit = isCanada
                    ? true
                    : centroid[0] > -160 && centroid[0] < -67
                  return (
                    <g key={geo.rsmKey + '-name'}>
                      {cur &&
                        geoLimit &&
                        (Object.keys(geoOffsets).indexOf(cur.id) === -1 ? (
                          <Marker coordinates={centroid}>
                            <text fontSize={10} textAnchor="middle">
                              {cur.id}
                            </text>
                          </Marker>
                        ) : (
                          <Annotation
                            subject={centroid}
                            dx={geoOffsets[cur.id][0]}
                            dy={geoOffsets[cur.id][1]}
                            connectorProps={<path />}
                          >
                            <text fontSize={10} alignmentBaseline="middle">
                              {cur.id}
                            </text>
                          </Annotation>
                        ))}
                    </g>
                  )
                })}
              </>
            )}
          </Geographies>
        </ComposableMap>
      </div>
      <div className="absolute left-4 bottom-4 flex w-28 flex-col text-xs md:w-64 md:text-sm">
        <span className="font-bold">{metric.label}</span>
        <div className="from-sp-geography-map-least to-sp-geography-map-most h-4 w-full bg-gradient-to-r md:h-6" />
        <div className="flex w-full items-center justify-between">
          <span>Less</span>
          <span>More</span>
        </div>
      </div>
      <div
        className="absolute right-3 top-3 hidden flex-col gap-1 md:flex"
        data-tip=""
      >
        {[
          totals,
          ...reports.filter((x) => {
            if (!x.geography || !x.geography.country) return false

            if (
              x.geography.region &&
              SKIP_REGIONS.includes(x.geography.region.slug)
            )
              return false

            return !x.geography.region || !x.geography.state_province
          }),
        ]
          .sort((x, y) => {
            if (x.geography.region && !y.geography.region) return 1
            if (!x.geography.region && y.geography.region) return -1
            return 0
          })
          .map((x, index) => {
            const label = x.geography.region
              ? x.geography.region.name
              : x.geography.country.name
            return (
              <div
                key={index}
                className="flex cursor-pointer items-center gap-2"
                onMouseEnter={() => {
                  setTooltipReport({ label, report: x })
                }}
                onMouseLeave={() => {
                  setTooltipReport(null)
                }}
              >
                <span
                  className="flex h-7 w-7 items-center justify-center rounded-full p-1 text-sm text-white"
                  style={{
                    backgroundColor: getGeographyColorPalette(
                      metric.value === 'opportunity_score'
                        ? x[metric.value] / reports[0][metric.value]
                        : x[metric.value] / 100
                    ),
                  }}
                >
                  {x[metric.value]}
                </span>
                <span className="text-sm">{label}</span>
              </div>
            )
          })}
      </div>
      {tooltipReport && (
        <ReactTooltip type="light" place="bottom" effect="float">
          {renderTip()}
        </ReactTooltip>
      )}
    </div>
  )
}
