import React, { FunctionComponent, useState } from 'react'
import Image from 'next/image'
import ReactMarkdown from 'react-markdown'

interface IProps {
  /**
   * Head caption
   */
  header?: string
  /**
   * The message to be displayed
   */
  message: string
  /**
   * Additional classes
   */
  className?: string
  /**
   * markdown
   */
  markdown?: boolean
  /**
   * persistent flag
   */
  persistent?: boolean
  /**
   * simple tip
   */
  simple?: boolean
}

export const ProTip: FunctionComponent<IProps> = ({
  header = '',
  message,
  className = '',
  markdown = false,
  persistent = false,
  simple = false,
}) => {
  const [hidden, setHidden] = useState(false)
  const [fullVisible, setFullVisible] = useState(simple)

  if (!message || hidden) return null

  return (
    <div
      className={`${
        simple
          ? 'w-full'
          : 'relative mx-auto flex w-full items-center gap-3 rounded-md bg-sp-primary-mid px-4 py-6 md:w-4/6'
      } ${className}`}
    >
      {!simple && !persistent ? (
        <div
          className="absolute top-1 right-2 cursor-pointer text-sm font-bold text-sp-purple"
          onClick={() => setHidden(true)}
        >
          X
        </div>
      ) : (
        ''
      )}
      {!simple && (
        <Image
          src="/images/tip.png"
          alt=""
          width={78}
          height={40}
          objectFit="contain"
        />
      )}
      <div className="flex flex-1 flex-col">
        {markdown ? (
          <ReactMarkdown
            className={`react-markdown ${
              fullVisible ? '' : 'line-clamp-3 md:line-clamp-none'
            }`}
          >
            {message}
          </ReactMarkdown>
        ) : (
          <span
            className={`text-sm text-sp-purple ${
              fullVisible ? '' : 'line-clamp-3 md:line-clamp-none'
            }`}
          >
            {header ? <strong>{header}&nbsp;</strong> : ''}
            {message}
          </span>
        )}
        {!simple && (
          <span
            className="block cursor-pointer self-end text-sm text-sp-primary underline md:hidden"
            onClick={() => setFullVisible((prev) => !prev)}
          >
            {fullVisible ? 'Less' : 'Read more'}
          </span>
        )}
      </div>
    </div>
  )
}
