function plural (interval: number): string {
    return (Math.floor(interval) === 1) ? "":"s"
}

export function timeSince(date: string) {
    let seconds = Math.floor((new Date().valueOf() - Date.parse(date)) / 1000);

    let interval = seconds / 31536000;
    if (interval > 1) {
        return `${Math.floor(interval)} year${plural(interval)} ago`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return `${Math.floor(interval)} month${plural(interval)} ago`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return `${Math.floor(interval)} day${plural(interval)} ago`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return `${Math.floor(interval)} hour${plural(interval)} ago`;
    }
    interval = seconds / 60;
    if (interval > 1) {
        return `${Math.floor(interval)} minute${plural(interval)} ago`;
    }
    return `${Math.floor(seconds)} second${plural(interval)} ago`;
}

export function friendlyDate(date: string) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
    const timestamp = Date.parse(date);

    return new Date(timestamp).toLocaleDateString("en-US", options);
}