import React, { FunctionComponent } from 'react';
import Link from 'next/link'

import { IPage } from '../../@types/generated/contentful';

type Props = {
    content: IPage
}

const FooterNavigationLink: FunctionComponent<Props> = ({content}) => {
    return (
        <li className='my-1 hover:underline'>
            <Link href={`/${content.fields.slug}`}>
                <a>{content.fields.title}</a>
            </Link>
        </li>   
    );
};

export default FooterNavigationLink;