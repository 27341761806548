declare global {
  interface Window {
      dataLayer: any
  }
}

if (typeof window !== "undefined") {
  window.dataLayer = window.dataLayer || [];
}

export function clearQuery() {
  window.dataLayer.push({ event: "clear_query", query: undefined})
}

export function sendEvent(event: string, data?: any) {
  const gtm = {
    event,
    ...data
  }
  window.dataLayer.push(gtm);

}