import React, { FunctionComponent } from 'react';

import { IField, IFieldGroup } from '../../../@types/hubspot'
import FormCheckbox from '../checkbox';
import FormInput from '../input';
import FormSelect from '../select';
import FormTextArea from '../textarea';

type Props = {
    fieldGroup?: IFieldGroup,
    fields: any
    callback: Function
}
const FieldGroup: FunctionComponent<Props> = ({fieldGroup, fields, callback}) => {

    const children = fieldGroup?.fields.map((field) => {
        switch(field.fieldType) {
            case 'dropdown':
                return <FormSelect key={`input-${field.name}`} field={fields[field.name]} callback={callback}/>
            case 'email':
            case 'single_line_text':
                return <FormInput key={`input-${field.name}`} field={fields[field.name]} callback={callback}/>
            case 'multi_line_text':
                return <FormTextArea key={`input-${field.name}`} field={fields[field.name]} callback={callback}/>
            case 'single_checkbox':
                return <FormCheckbox key={`input-${field.name}`} field={fields[field.name]} callback={callback}/>
            default:
                return <></>
        }
    })

    switch(fieldGroup?.fields.length) {
        case 2:
            return <div className="grid md:grid-cols-2 md:gap-6">{children}</div>
        case 3:
            return <div className="grid md:grid-cols-3 md:gap-6">{children}</div>
        default:
            return <div className="grid grid-cols-1">{children}</div>
    }
};

export default FieldGroup;