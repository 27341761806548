export function convertRemToPixels(rem: number) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const isOpportunityScore = (sortBy: string) => {
    switch (sortBy) {
        case "engagement":
        case "daily_weekly":
        case "momentum":
        case "passion":
        case "excitement":
        case "consideration":
        case "favorability":
            return false;
        default:
            return true;
    }
};

export const getGeographyColorPalette = (value: number) => {
    //value from 0 to 1
    var hue = ((1 - value) * 60).toString(10)
    return ['hsl(', hue, ',100%,50%)'].join('')
}
