import React, { FunctionComponent } from 'react';
import { IHomeInsights } from '../../../@types/generated/contentful';
import HomeBlogPostTile from '../homeBlogPostTile';
import dynamic from 'next/dynamic'

type Props = {
    content: IHomeInsights,
};

const CallToAction = dynamic(() => import('../../callToAction'), {
    ssr: false,
  })

const HomeInsights: FunctionComponent<Props> = ({ content }) => {
    const { seoCopy, title, body, blogPosts, ctaButton } = content.fields;

    const btnCTA = ctaButton
        ? <CallToAction key={ctaButton.sys.id} content={ctaButton.fields} callback={() => {}}/>
        : <></>
    
    return (
        <div className="bg-sp-light-purple">
            <div className="container mx-auto flex flex-col px-6 pt-6 pb-14 md:px-8 md:pt-10 md:pb-20">
                <div className="flex flex-col w-full xl:w-6/12">
                    <div className="seo-copy">{seoCopy}</div>
                    <h2 className="my-2">{title}</h2>
                    <div className="body-large">{body}</div>
                </div>
                <div className="grid grid-cols-12 grid-rows-2 mt-10">
                    {blogPosts?.map((post, index) => (
                        <HomeBlogPostTile
                            key={post.sys.id}
                            title={post.fields.title}
                            image={post.fields.image}
                            slug={post.fields.slug}
                            date={post.fields.date}
                            index={index}
                        />
                    ))}
                </div>
                <div className="mt-4 mx-auto">
                    { btnCTA }
                </div>
            </div>
        </div>
    );
};

export default HomeInsights;
