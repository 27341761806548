import { UTM } from '../services/utm'

export function isExternal(url: string) {
    return (url &&
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(url));
}

export function isPlatform(url: string) {
    return (url.includes('global.sponsorpulse.com') ||
        url.includes('global.sponsorpulseimi.com'));
}

export function getQueryString(url: string, utm: UTM) {
    const questionMark = url.includes('?')
    const queryString = `${questionMark ? '&' : '?'}${utm.getUtmUrlString()}`
    return queryString;
}