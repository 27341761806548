import React, { FunctionComponent } from 'react'
import { isOpportunityScore } from '../../helpers/utils'

interface Props {
  data: any[]
  sortBy: string
}

export const ScoreAxis: FunctionComponent<Props> = ({ data, sortBy }) => {
  let yMax = 100
  if (data.length > 0) {
    const maxScore = Math.max.apply(
      Math,
      data.map((report) => report[sortBy])
    )
    if (maxScore > 0) yMax = maxScore
  }

  const nStep = Math.ceil(yMax / 10) + 1
  const suffix = !isOpportunityScore(sortBy) ? '%' : ''
  return (
    <div className="flex flex-col-reverse">
      {Array.from(Array(nStep).keys()).map((index) => (
        <div key={index} className="flex h-10 w-full items-end">
          <span className="h-3 flex-1 text-base leading-none">
            {index * 10} {suffix}
          </span>
          <div className="h-px w-2 bg-gray-400" />
        </div>
      ))}
    </div>
  )
}
