import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { IPricingFocus, IPricingSection } from '../../../@types/generated/contentful';
import TitleBrick from '../../titleBrick';
import PricingFocus from '../pricingFocus';

type Props = {
    content: IPricingSection,
};

const PricingSection: FunctionComponent<Props> = ({ content }) => {
	const [activeFocus, setActiveFocus] = useState<IPricingFocus>(content.fields.focuses[0]);

	const { titleBrick, focuses, text } = content.fields;
	
	useEffect(() => {
		if (focuses && focuses.length > 0) setActiveFocus(focuses[0]);
	}, [focuses]);

    return (
        <div className="flex flex-col">
			<TitleBrick content={titleBrick} />
			<div style={{backgroundColor: titleBrick.fields.background || "white"}}>
				<div className="container mx-auto w-full md:w-fit flex items-center -mt-6 p-6 md:px-8 md:pt-0 md:pb-8">
					{focuses?.map((focus) => (
						<div
							key={focus.sys.id}
							className={`w-1/2 md:w-40 h-16 p-4 rounded ${activeFocus?.fields.period === focus.fields.period ? 'text-white bg-sp-primary shadow-xl' : 'text-black bg-white'} flex flex-col justify-center items-center cursor-pointer`}
							onClick={() => setActiveFocus(focus)}
						>
							<span className="sub-heading-medium text-inherit font-normal">{focus.fields.period}</span>
                            {focus.fields.description ? (
                                <span className="small-label text-inherit">{focus.fields.description}</span>
                            ) : ''}
						</div>
					))}
				</div>
			</div>
			<PricingFocus content={activeFocus} />
            {text ? (
                <div className="container mx-auto">
                    <div className="w-full md:w-2/3 lg:w-1/2 mx-auto px-6 pb-14 md:px-8 md:pb-16 text-center">
                        <ReactMarkdown className="large-markdown">{text}</ReactMarkdown>
                    </div>
                </div>
            ) : ''}
        </div>
    );
};

export default PricingSection;
