import React, { FunctionComponent, useEffect, useState } from 'react'
import Image from 'next/image'
import {
  isProperty,
  isConsumerSegment,
  isBrand,
  isPurchaseBehavior,
  isDigitalBehavior,
} from './propertyAxis'
import { IProperty } from '../../@types/common'
import { IConsumerSegment } from '../../@types/dataTable'

interface IReportImage {
  /**
   * Property report
   */
  report: {
    consumer_segment?: IConsumerSegment
    property?: IProperty
  }
}

export const ReportImage: FunctionComponent<IReportImage> = ({ report }) => {
  const [imgSrc, setImgSrc] = useState('')
  const fallbackImageSrc = `https://${process.env.NEXT_PUBLIC_CDN_URL}/behavior-icons/my_behaviors.svg`

  useEffect(() => {
    if (!report) return

    const getImageUrl = (report: any) => {
      if (report.property && isProperty(report.property)) {
        const property: IProperty = report.property
        const src = `https://${process.env.NEXT_PUBLIC_CDN_URL}/property-images/${property.code}.png`
        return src
      }

      if (
        report.consumer_segment &&
        isConsumerSegment(report.consumer_segment)
      ) {
        const consumerSegment = report.consumer_segment

        let imageSrc = `https://${process.env.NEXT_PUBLIC_CDN_URL}/behavior-icons/my_behaviors.svg`
        if (isBrand(consumerSegment)) {
          imageSrc = `https://${process.env.NEXT_PUBLIC_CDN_URL}/brand-images/${consumerSegment.code}.png`
        } else if (
          isPurchaseBehavior(consumerSegment) ||
          isDigitalBehavior(consumerSegment)
        ) {
          imageSrc = `https://${
            process.env.NEXT_PUBLIC_CDN_URL
          }/behavior-icons/${consumerSegment.category!.slug}.svg`
        }

        return imageSrc
      }

      return fallbackImageSrc
    }

    setImgSrc(getImageUrl(report))
  }, [report, fallbackImageSrc])

  if (!imgSrc.length) return null

  return (
    <Image
      src={imgSrc}
      alt=""
      width={50}
      height={50}
      objectFit="contain"
      onError={() => setImgSrc(fallbackImageSrc)}
    />
  )
}
