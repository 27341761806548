import React, { FunctionComponent } from 'react'
import Image from 'next/image'
import { ITitleBrick } from '../../@types/generated/contentful'
import dynamic from 'next/dynamic'

type Props = {
  content: ITitleBrick
}

const CallToAction = dynamic(() => import('../callToAction'), {
  ssr: false,
})

const TitleBrick: FunctionComponent<Props> = ({ content }) => {
  const { seoCopy, title, body, image, background, ctaButton, callToAction } =
    content.fields

  const btnCTA = ctaButton ? (
    <CallToAction content={ctaButton.fields} callback={() => {}} />
  ) : (
    <></>
  )

  return (
    <div style={{ backgroundColor: background }}>
      {image ? (
        <div className="container mx-auto grid grid-cols-12 items-center p-6 md:p-8">
          <div className="col-span-12 py-2 md:col-span-6 md:px-6">
            <Image
              src={`https:${image.fields.file.url}`}
              alt={image.fields.description}
              width={image.fields.file.details.image?.width}
              height={image.fields.file.details.image?.height}
              objectFit="contain"
            />
          </div>
          <div className="col-span-12 flex flex-col justify-end py-2 md:col-span-6 md:pb-10">
            <div className="seo-copy">{seoCopy}</div>
            <h1 className="my-3">{title}</h1>
            <div className="body-text w-full md:w-8/12">{body}</div>
            <div className="mt-6 flex flex-col items-center md:flex-row md:flex-wrap md:justify-center">
              {callToAction && callToAction.length > 0
                ? callToAction.map((cta, index) => (
                    <CallToAction
                      key={index}
                      content={cta.fields}
                      callback={() => {}}
                      className={`${index === 0 ? '' : 'mt-3 md:ml-3 md:mt-0'}`}
                    />
                  ))
                : btnCTA}
            </div>
          </div>
        </div>
      ) : (
        <div className="container mx-auto flex min-h-220 w-full items-center justify-center px-6 py-8 md:min-h-400 md:px-12 md:py-14">
          <div className="w-full md:w-2/3">
            <h1 className="text-center">{title}</h1>
            {body && body.length ? (
              <>
                <div className="body-text mt-6 text-center">{body}</div>
                <div className="mt-6 flex flex-col items-center md:flex-row md:flex-wrap md:justify-center">
                  {callToAction && callToAction.length > 0
                    ? callToAction.map((cta, index) => (
                        <CallToAction
                          key={index}
                          content={cta.fields}
                          callback={() => {}}
                          className={`${
                            index === 0 ? '' : 'mt-3 md:ml-3 md:mt-0'
                          }`}
                        />
                      ))
                    : btnCTA}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default TitleBrick
