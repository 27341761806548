import React, { FunctionComponent, useEffect, useState } from 'react';
import Image from 'next/image';

type Props = {
    propertyName: string,
    propertyCode: string,
    size: number,
}

const PropertyImage: FunctionComponent<Props> = ({ propertyName, propertyCode, size }) => {
    const [imgSrc, setImgSrc] = useState("");

    useEffect(() => {
        setImgSrc(`https://${process.env.NEXT_PUBLIC_CDN_URL}/property-images/${propertyCode}.png`);
    }, [propertyCode]);
    
    if (!imgSrc.length) return null;

    return (
        <Image
            src={imgSrc}
            alt={propertyName}
            width={size}
            height={size}
            objectFit="contain"
            onError={() => setImgSrc("/sponsorpulse-badge-color.png")}
        />
    );
}

export default PropertyImage;