import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import { IGetStarted } from '../../@types/generated/contentful';
import dynamic from 'next/dynamic'

type Props = {
    content: IGetStarted,
};

const CallToAction = dynamic(() => import('../callToAction'), {
    ssr: false,
  })

const GetStarted: FunctionComponent<Props> = ({ content }) => {
    const { title, body, ctaButton, image, background } = content.fields;

    const btnCTA = ctaButton
        ? <CallToAction key={ctaButton.sys.id} content={ctaButton.fields} callback={() => {}}/>
        : <></>

    return (
        <div style={{backgroundColor: background}}>
            <div className="container mx-auto grid grid-cols-12 px-6 pt-8 pb-10 md:px-8 md:pt-10 md:pb-12 items-center">
                <div className="col-span-12 md:col-span-7">
                    {image ? (
                        <Image
                            src={`https:${image.fields.file.url}`}
                            alt={image.fields.description}
                            width={image.fields.file.details.image?.width}
                            height={image.fields.file.details.image?.height}
                            objectFit="contain"
                        />
                    ) : ("")}
                </div>
                <div className="col-span-12 md:col-span-5 flex flex-col m-auto">
                    <h2>{title}</h2>
                    <div className="body-large mt-2">{body}</div>
                    <div className="mt-4">
                        { btnCTA }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetStarted;
