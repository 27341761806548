import React, { FunctionComponent } from 'react';
import Spinner from '../../spinner';

type Props = {
    isSubmitting: boolean,
    isCustom: boolean
}
const FormSubmit: FunctionComponent<Props> = ({ isSubmitting, isCustom=false }) => {

    return (
        <button className={`bg-sp-primary leading-7 rounded text-white ${(isCustom)? 'w-full': 'w-full md:w-56'} py-2`} disabled={isSubmitting}>
            { (isSubmitting) ?       
                <>
                    Sending <div className='inline-block w-4 h-4'><Spinner /></div>
                </>
            :
                <>
                    Submit
                </>
            }
        </button>
    );
};

export default FormSubmit;