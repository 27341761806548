import { IUtm } from "../@types/utm";

export class UTM {
    constructor() {
        if (typeof window !== "undefined") {
            const utm = this.getUtmFromURL();

            this.setUtm(utm);
        }
    }

    private setUtm(utm: IUtm | null) {
        if (!utm) this.clearUtm();
        else localStorage.setItem('utm',JSON.stringify(utm));
    }

    private getUtmFromURL() : IUtm | null {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const campaign = urlParams.get('utm_campaign') || '';
        const medium = urlParams.get('utm_medium') || '';
        const source = urlParams.get('utm_source') || '';
        const content = urlParams.get('utm_content') || '';
        const term = urlParams.get('utm_term') || '';

        if ( campaign == '' && medium == '' && source == '' && content == '' && term == '' ) {
            return null
        }

        return  {
            utm_campaign: campaign,
            utm_medium: medium,
            utm_source: source,
            utm_content: content,
            utm_term: term
        }
    }

    public clearUtm() {
        localStorage.removeItem('utm')
    }

    public getUtm() {
        const utm = localStorage.getItem('utm')
        return (utm) ? JSON.parse(utm) : {};
    }

    public getUtmUrlString() {
        const utmParams = this.getUtm();
        const str = Object.keys(utmParams).map(function(key) {
            return key + '=' + utmParams[key];
        }).join('&');
        return str;
    }
}