import React, { FunctionComponent, useState } from 'react';
import Autosuggest, { RenderInputComponentProps, RenderSuggestionsContainerParams } from 'react-autosuggest';
import Image from 'next/image';
import PropertyImage from '../../propertyImage';

type Props = {
    searchText: string,
    properties: Object[],
};

const SearchPropertyInput: FunctionComponent<Props> = ({ searchText, properties }) => {
    const [suggestions, setSuggestions] = useState<Object[]>([]);
    const [value, setValue] = useState("");

    const onChange = (event: any, { newValue = "" }) => {
        setValue(newValue);
    };
    
    const inputProps = {
        placeholder: searchText,
        value,
        onChange,
    };

    const onSuggestionsFetchRequested = ({value = ""}) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const getSuggestions = (value: string) => {
        const inputValue = value.trim().toLowerCase();
    
        if (inputValue.length === 0) return [];
        return properties.filter((property: any) => property.name.toLowerCase().startsWith(inputValue));
    };

    const getSuggestionValue = (suggestion: any) => suggestion.name;
    
    const renderInputComponent = (inputProps: RenderInputComponentProps) => {
        return (
            <div className="w-full md:w-96 flex items-center bg-white px-4 rounded-md border border-slate-300">
                <input {...inputProps} className="flex-1 outline-transparent border-transparent ring-transparent focus:outline-transparent focus:border-transparent focus:ring-transparent mr-2 px-0" />
                <Image
                    src="/icons/search.png"
                    alt=""
                    width={20}
                    height={20}
                />
            </div>
        );
    };

    const renderSuggestion = (suggestion: any) => {
        return (
            <div className="flex items-center border-b px-3 py-2 text-base">
                <PropertyImage
                    propertyName={suggestion.name}
                    propertyCode={suggestion.value}
                    size={30}
                />
                <span className="body-extra-small flex-1 mx-2">{suggestion.name}</span>
                <a className="body-extra-small border border-sp-primary rounded text-sp-primary px-2 py-1" href="https://global.sponsorpulseimi.com/sign_up" rel="noopener noreferrer" target="_blank">
                    Sign up for free
                </a>
            </div>
        );
    };

    const renderSuggestionsContainer = (container: RenderSuggestionsContainerParams) => {
        return (
            <div {...container?.containerProps} className="absolute top-14 w-full md:w-96 max-h-40 overflow-y-scroll no-scrollbar rounded-md bg-white shadow-md z-10">
                {container?.children}
            </div>
        );
    }

    return (
        <Autosuggest
            id="search-property-input"
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderInputComponent={renderInputComponent}
            renderSuggestionsContainer={renderSuggestionsContainer}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
        />
    );
};

export default SearchPropertyInput;
