import React, { FunctionComponent } from 'react';
import Image from 'next/image';
import { INewsTile } from '../../@types/generated/contentful';

type Props = {
    content: INewsTile,
};

const NewsTile: FunctionComponent<Props> = ({ content }) => {
    const { image, url } = content.fields;

    return (
        <a rel='noopener noreferrer' target='_blank' href={url || "#"}>
            <div className="flex mx-4">
                {image ? (
                    <Image
                        src={`https:${image.fields.file.url}`}
                        alt={image.fields.description}
                        width={140}
                        height={140}
                        objectFit="contain"
                    />
                ) : ("")}
            </div>
        </a>
    );
};

export default NewsTile;
