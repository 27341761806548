import React, { FunctionComponent } from 'react';
import { ITeamSection } from '../../../@types/generated/contentful';
import Carousel from '../../carousel';
import MemberTile from '../memberTile';

type Props = {
    content: ITeamSection,
};

const TeamSection: FunctionComponent<Props> = ({ content }) => {
    const { seoCopy, title, body, members } = content.fields;

    return (
        <div className="container mx-auto flex flex-col p-6 md:pt-8 md:pb-12">
            <div className="flex flex-col w-full md:w-5/12 ml-0 md:ml-8">
                <div className="seo-copy">{seoCopy}</div>
                <h2 className="my-1">{title}</h2>
                <div className="body-large">{body}</div>
            </div>
            <div className="block md:hidden mt-4">
                <Carousel>
                    {members?.map((member, index) => (
                        <div key={member.sys.id}>
                            <MemberTile
                                content={member}
                                className={`w-80 flex-none ${index === 0 ? 'm-0' : 'ml-4' }`}
                            />
                        </div>
                    ))}
                </Carousel>
            </div>
            <div className="hidden md:grid grid-cols-12 mt-6 gap-x-4 gap-y-6">
                {members?.map((member) => (
                    <MemberTile
                        key={member.sys.id}
                        content={member}
                        className="md:col-span-6 lg:col-span-4"
                    />
                ))}
            </div>
        </div>
    );
};

export default TeamSection;
