import React, { FunctionComponent } from 'react';

import { IField } from '../../../@types/hubspot';

import FormFieldError from '../fieldError'

type Props = {
    field: IField,
    callback: Function
}
const FormTextArea: FunctionComponent<Props> = ({field, callback}) => {

    const validateField = (value: string) => {
        callback({
            ...field,
            value: value
        })
    }

    return (
        <div className='relative z-0 w-full mb-6 group'>
            <div className={`relative z-0 ${(field.error)? 'bg-sp-error-red border border-red-500':'border border-sp-mid-grey bg-white'} rounded w-full pt-2 pb-1 group`}>
            <textarea 
                id={field.name}
                name={field.name}
                rows={13}
                className="form-textarea border-0 block mt-1.5 w-full text-base leading-4 text-sp-purple bg-transparent appearance-none focus:outline-none focus:ring-0 peer resize-none"
                placeholder=" "
                required={field.required}
                aria-required={field.required}
                aria-invalid={(field.error) ? true : false}
                aria-errormessage={`error-${field.name}`}
                onBlur={(event) => validateField(event.target.value)}
            />
            <label 
                htmlFor={field.name}
                className="peer-focus:font-medium absolute text-base text-sp-mid-grey duration-300 transform -translate-y-3 scale-[0.625] top-3 -z-10 origin-[0] left-3 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1 peer-focus:scale-[0.625] peer-focus:-translate-y-3"
            >{field.label}{(!field.required)&&' (optional)'}</label>
            <FormFieldError field={field} />
        </div>
        </div>

    );
};

export default FormTextArea;