import React, { FunctionComponent } from 'react';
import { IHomeTestimonials } from '../../../@types/generated/contentful';
import TestimonialTile from '../../testimonialTile';
import dynamic from 'next/dynamic';

type Props = {
    content: IHomeTestimonials,
};

const CallToAction = dynamic(() => import('../../callToAction'), {
    ssr: false,
  })

const HomeTestimonials: FunctionComponent<Props> = ({ content }) => {
    const { seoCopy, title, body, ctaButton, testimonials, background } = content.fields;

    const btnCTA = ctaButton
        ? <CallToAction key={ctaButton.sys.id} content={ctaButton.fields} callback={() => {}}/>
        : <></>
    
    return (
        <div style={{backgroundColor: background}}>
            <div className="container mx-auto grid grid-cols-12 px-6 pt-12 pb-16 md:px-8 md:pt-16 md:pb-20">
                <div className="flex flex-col col-span-12 md:col-span-5 mr-0 md:mr-auto">
                    <div className="seo-copy">{seoCopy}</div>
                    <h2 className="my-3">{title}</h2>
                    <div className="body-large">{body}</div>
                    <div className="my-4">
                        { btnCTA }
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row items-center justify-none md:justify-center col-span-12 md:col-span-7">
                    {testimonials?.map((testimonial, index) => (
                        <TestimonialTile
                            key={testimonial.sys.id}
                            index={index}
                            content={testimonial}
                            className={`${index === 0 ? "m-0" : "mt-2 ml-0 lg:mt-0 lg:ml-2"}`}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HomeTestimonials;
