import React, { FunctionComponent } from 'react';
import { IHomeNews } from '../../../@types/generated/contentful';
import NewsTile from '../../newsTile';

type Props = {
    content: IHomeNews,
};

const HomeNews: FunctionComponent<Props> = ({ content }) => {
    const { seoCopy, title, newsItems } = content.fields;

    return (
        <div className="container mx-auto grid grid-cols-12 items-center px-6 pt-16 pb-12 md:px-8 md:pt-20 md:pb-16">
            <div className="flex flex-col col-span-12 md:col-span-6 mr-0 md:mr-4">
                <div className="seo-copy">{seoCopy}</div>
                <h2 className="my-2">{title}</h2>
            </div>
            <div className="col-span-12 md:col-span-6 flex flex-row items-center justify-between">
                {newsItems?.map((news) => (
                    <NewsTile
                        key={news.sys.id}
                        content={news}
                    />
                ))}
            </div>
        </div>
    );
};

export default HomeNews;
