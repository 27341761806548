import { ICategory, IProperty } from './common'

export interface IConsumerSegment {
  name: string
  type?: 'brand' | 'demographic' | 'purchase_behavior' | 'digital_behavior'
  code?: string
  category?: ICategory
}

export interface ITableData {
  [key: string]: number | IProperty | string | IConsumerSegment
}

export interface ITableHeader {
  key: string
  label: string
  type: 'label' | 'number' | 'percentage' | 'property' | 'consumer_segment'
  tooltip?: string
}

export enum TableType {
  ANALYZE = 'analyze_property',
  COMPARE = 'compare_properties',
  TREND = 'trend_properties',
}
