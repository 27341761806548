import React, { FunctionComponent } from 'react'
import { ScoreAxis } from './scoreAxis'
import { PropertyAxis } from './propertyAxis'
import { GraphBar } from './graphBar'
import { ITableData, TableType } from '../../@types/dataTable'

interface IDataGraph {
  data: ITableData[]
  totals?: ITableData
  sortBy:
    | 'opportunity_score'
    | 'engagement'
    | 'daily_weekly'
    | 'momentum'
    | 'passion'
    | 'excitement'
    | 'consideration'
    | 'favorability'
    | ''
  type: TableType
}

export const DataGraph: FunctionComponent<IDataGraph> = ({
  data,
  totals = undefined,
  sortBy,
  type,
}) => {
  let graphData
  if (type === TableType.ANALYZE && totals) {
    graphData = [totals, ...data.slice(0, 8)]
  } else {
    graphData = data.slice(0, 9)
  }
  return (
    <>
      <div className="grid w-full grid-cols-data-graph grid-rows-data-graph">
        <ScoreAxis data={graphData} sortBy={sortBy} />
        <GraphBar
          data={graphData}
          sortBy={sortBy}
          callback={(index: number) => {}}
        />
        <PropertyAxis data={graphData} />
      </div>
    </>
  )
}
