import React, { FunctionComponent } from 'react'
import { useRouter } from 'next/router'
import { Entry } from 'contentful'
import RichTextPage from '../richText/richTextPage'
import Footer from '../footer'
import HubspotForm from '../form/hubspotForm'
import Video from '../video'
import TitleBrick from '../titleBrick'
import HomeSolutions from '../home/homeSolutions'
import HomeApproach from '../home/homeApproach'
import HomeInsights from '../home/homeInsights'
import HomeDifference from '../home/homeDifference'
import HomeTestimonials from '../home/homeTestimonials'
import HomeNews from '../home/homeNews'
import GetStarted from '../getStarted'
import SolutionTestimonial from '../solutions/solutionTestimonial'
import SolutionSection from '../solutions/solutionSection'
import PricingSection from '../pricing/pricingSection'
import SponsorshipSection from '../properties/sponsorshipSection'
import SearchPropertySection from '../properties/searchPropertySection'
import StorySection from '../about/storySection'
import CareerSection from '../about/careerSection'
import TeamSection from '../about/teamSection'
import FaqSection from '../faqSection'
import CodeBlock from '../codeBlock'
import Report from '../report'
import SolutionTab from '../solutions/solutionTab'

const Components = {
  footer: Footer,
  richText: RichTextPage,
  hubspotForm: HubspotForm,
  video: Video,
  titleBrick: TitleBrick,
  homeSolutions: HomeSolutions,
  homeDifference: HomeDifference,
  homeApproach: HomeApproach,
  homeInsights: HomeInsights,
  homeTestimonials: HomeTestimonials,
  homeNews: HomeNews,
  getStarted: GetStarted,
  solutionTestimonial: SolutionTestimonial,
  solutionSection: SolutionSection,
  solutionTab: SolutionTab,
  pricingSection: PricingSection,
  sponsorshipSection: SponsorshipSection,
  searchPropertySection: SearchPropertySection,
  storySection: StorySection,
  careerSection: CareerSection,
  teamSection: TeamSection,
  faqSection: FaqSection,
  codeBlock: CodeBlock,
  report: Report,
}

type BlockProps = {
  block: Entry<any>
}

const Block: FunctionComponent<BlockProps> = ({ block }) => {
  const router = useRouter()

  // component does exist
  if (
    typeof Components[block.sys.contentType.sys.id as keyof Object] !==
    'undefined'
  ) {
    const embedded =
      (router.asPath.includes('/insights/') &&
      block.sys.contentType.sys.id === 'hubspotForm') ||
      block.sys.contentType.sys.id === 'solutionTab'
    const element = React.createElement(
      Components[block.sys.contentType.sys.id as keyof Object] as any,
      {
        key: block.sys.id,
        content: block,
        customized: embedded,
      }
    )
    return <div id={block.sys.contentType.sys.id}>{element}</div>
  }
  // component doesn't exist yet
  return React.createElement(
    () => (
      <div className="container mx-auto">
        The component <b>{block.sys.contentType.sys.id}</b> has not been created
        yet.
      </div>
    ),
    { key: block.sys.id }
  )
}

export default Block
