import React, { FunctionComponent } from 'react';
import Image from 'next/image';

type Props = {
    image: string | undefined
    name: string | undefined,
    className?: string,
}

const ProfilePic: FunctionComponent<Props> = ({ image = '/default-profile-image.jpeg', name = 'SponsorPulse Staff', className = "" }) => {
    image = (image === '/default-profile-image.jpeg') ? image:`https:${image}`
    return (
        <div className={`relative w-16 ${className}`}>
            <Image 
                src={image}
                className="w-8 h-8 rounded-full"
                alt={name}
                width={54}
                height={54}
            />
        </div>
    );
};

export default ProfilePic;