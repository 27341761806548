import React, { FunctionComponent } from 'react';

type Props = {
    message: string
}
const FormError: FunctionComponent<Props> = ({ message }) => {
    if (!message) return <></>

    return (
        <div className='rounded bg-red-500 p-2 mb-2'>
            <span className='text-md text-white ml-1'>
                { message }
            </span>
        </div>
    );
};

export default FormError;